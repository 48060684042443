import { JigConst, UtilsConst } from '@/constants'
import { LockRequestPayload, Permission } from '@/store/modules/jig/types'
import jigCategoryOptionsJson from '@/store/modules/JigCategoryOptions.json'
import { JigSummaryStats, ReportJigSummaryStats } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'

export default {
  jigDailyActivityData: [] as JigSummaryStats[],
  jigDailyViewsData: [] as ReportJigSummaryStats[],
  myJigs: [],
  teamJigs: [],
  jigMetadata: {
    Id: '',
    ProjectName: '',
    FormatVersion: 0,
    Password: undefined,
    ProjectVisibility: JigConst.JigVisibilities.Link, // default to `link`
  },
  jigDetailPermissions: [] as Permission[],
  copyJigResult: null,
  saveJigResult: null,
  saveJigVisibilityResult: null,
  isStatisticsLoading: false,
  jigCategoryOptions: jigCategoryOptionsJson,
  jigListTotalPages: 1,
  myJigsListCurrentPage: 1,
  teamJigsListCurrentPage: 1,
  maxJigCountValue: UtilsConst.invalidNumber, // Max Jig Count might be per `tenatn` or per `user`. Store this separately so it won't mix up with `myTenantTotalJigCount` value
  myTenantTotalJigCount: UtilsConst.invalidNumber,
  myJigCount: UtilsConst.invalidNumber,
  myTenantPrivateJigCount: UtilsConst.invalidNumber,
  myTenantSharedJigCount: UtilsConst.invalidNumber,
  changeJigOwnerResult: {
    message: '',
    status: '',
  },
  uploadThumbnailResult: {
    status: '',
    thumbnailURL: '',
  },
  teamSharePermissions: [] as JigConst.SharePermission[],
  embedParamsConfig: {},
  lockPayload: { jigId: '', tenantId: TenantHelpers.InvalidTenantID } as LockRequestPayload,
}
