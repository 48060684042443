import { SubscriptionConst } from '@/constants'
import { default as state } from '@/store/modules/app/state'
import { Tenant, UserRoleDefinition, UserTenantListMap } from '@/store/modules/app/types'
import { FileType, StandardObject } from '@/store/types'
import { orderBy } from 'lodash'

export default {
  // eslint-disable-next-line lines-around-comment
  /**
   * Retrieves an array of allowed file extensions based on `dynaconfConfig`.
   * The result is a flattened array of file extensions.
   *
   * @returns { string[] } An array of allowed file extensions.
   */
  allowedModelFileFormats(): string[] {
    return state.dynaconfConfig.fileformats.fileTypes
      .filter((f: FileType) => f.enabled && f.uploadType === '3D Models')
      .map((f: FileType) => f.extensions)
      .flat()
  },
  orderedTenantUserRoles(): UserRoleDefinition[] {
    return orderBy(state.tenantUserRoles, ['DisplayName'], ['asc'])
  },
  tenantUserRolesMap(): StandardObject {
    const rolesMap: StandardObject = {}

    state.tenantUserRoles.forEach((role: UserRoleDefinition) => {
      // role.Name: role_teamadmin1, role_creator1 etc.
      rolesMap[role.Name.toLowerCase()] = role
    })

    return rolesMap
  },
  userTenantsDropdownMap(): UserTenantListMap[] {
    return state.myTenants.map((tenant: Tenant) => ({
      text: tenant.Name,
      value: tenant.ID,
    }))
  },
  isFreeTier(): boolean {
    return state.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierFree
  },
  isOrgTier(): boolean {
    return state.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierOrganization
  },
}
