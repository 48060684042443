import { JigConst, PermissionConst, SubscriptionConst } from '@/constants'
import { Color, NullableTime, StandardObject, TargetApp } from '@/store/types'

export interface CRUD {
  C?: boolean
  R?: boolean
  D?: boolean
  U?: boolean
}

export interface Permission {
  ID: number
  CRUD: CRUD
  ResID: number
  ResType: PermissionConst.PermissionsResourceType
  AudID: number
  AudName: string
  AudType: PermissionConst.PermissionsAudienceType
  ExpiresAt: NullableTime
}

export interface JigModelData {
  ModelName: string
  GeometryString: string
  HasTexture: boolean
  TextureString: string
  Materials: Material[]
  Meshes: Mesh[]
  Models: Model[]
  Textures: any[]
}

export interface Material {
  UID: string
  Id: number
  Name: string
  EmissiveColor: Color
}

export interface Mesh {
  UID: string
  Name: string
  MaterialId: number
  MaterialUID: string
  TextureUID: string
}

export interface Model {
  UID: string
  OriginalUID: string
  Uri: string
  FullFileName: string
  GeometryString: string
  HasTexture: boolean
  IsFlipped: boolean
  SubMeshes: Mesh[]
  Textures: any[]
  GeometryCanBeIgnored: boolean
}

export interface JigSceneData {
  BackgroundImage: string
  BackgroundColor: Color
}

export interface JigStageData {
  Stages: Stage[]
}

export interface Stage {
  StageInstructions: string
  StagePosition: StagePosition
  StageSound: Sound
  StageRotation: StagePosition
  StageScale: StagePosition
  Loop: boolean
  IsViewpointActiveInStage: boolean
  ViewpointDistance: number
  ViewpointPosition: StagePosition
  ViewpointRotation: StagePosition
  ViewpointTargetPosition: StagePosition
  SerializedElements: SerializedElement[]
}

export interface SerializedElement {
  ElementIndex: number
  ElementUniqueName: string
  ModelUID: string
  ElementData: ElementData
}

export interface ElementData {
  ObjectName: string
  Label: string
  LabelOnForThisStage: boolean
  ShowcaseOnThisStage: boolean
  MaterialUID: string
  TextureUID: string
  ParentName: string
  ParentObject: ParentObject
  ParentModelUID: string
  Color: Color
  EmissiveColor: Color
  Metallic: number
  Smoothness: number
  Normal: number
  Height: number
  Occlusion: number
  Sound: Sound
  Position: StagePosition
  Rotation: StagePosition
  Scale: StagePosition
  AutoSpinAxis: StagePosition
  AutoSpinSpeed: number
  AutoSpinOnThisStage: boolean
  Actions: any[]
}

export interface StagePosition {
  x: number
  y: number
  z: number
  w?: number
}

export interface ParentObject {
  instanceID: number
}

export interface Sound {
  Uri: string
  Delay: number
  Loop: boolean
}

export interface TenantJigPermissions {
  TenantID: number
  Permissions: JigConst.SharePermission[]
  Type?: string
}

export interface FeatureOption {
  enabled: boolean
  settingType: string
}

export interface JigFeatureOption {
  [SubscriptionConst.Features.AVPFeaturePullApartKey]: FeatureOption
}

export interface JigFeatures {
  options: JigFeatureOption
}

export interface UserActivity {
  LockExpiry: string
  LockOriginApp: string
  LockTaken: string
  LockedByUID?: number
  ModifiedByUid: number
  Username?: string
}

export interface JigMetadata {
  Author: string
  Categories: string[]

  CopyableBy: string

  DateCreated: string
  DatePublished: string
  DateUpdated: string
  DeeplinkURL?: string
  DeeplinkURLRequesting?: boolean

  FormatVersion: number
  FormatVersionAtCreation: number
  GlobalScale?: number
  Id: string
  MovoEnabled?: boolean
  ProjectDescription: string
  ProjectName: string
  ProjectVisibility: string
  RequestUserPermission: Permission
  OwnerTenantID?: number
  Password?: string
  PasswordDateCreated?: string
  Tags: string[]
  Tenants?: TenantJigPermissions[]
  ThumbnailURL: string
  Type: string
  Uid: number
  Version: number
  Views: number
  votes: number
  Features: JigFeatures
  UserActivity?: UserActivity
}

export interface JigShareRequest {
  jig: JigMetadata
  branchIOCustomData: any
  targetApp: TargetApp
  route: string
}

export interface JigMetrics {
  views: number
  avgViewingTimeSeconds: number
  avgViewingTime: string
  totalViewingTimeSeconds: number
  totalViewingTime: string
  uniqueUsersPresentingJig: number
}

export interface JigPermissionCreateRequest {
  UrlParams: {
    email: string
    type: string
  }
  JigId: string
}

export interface JigPermissionDeleteRequest {
  ResID: number
  PermID: number
}

export interface JigPermissionUpdateRequest {
  JigId: string
  Perm: Permission
}

export interface CopyJigResult {
  id: string
  name: string
}

export interface SaveJigResult {
  message: string
  status: string
  version: string
}

export interface SaveJigVisibilityResult {
  status: string
  visibility: string
  Password: string
  PasswordDateCreated: string
}

export interface JigCategoryOption {
  text: string
  value: string
}

export interface ChangeJigOwnerRequest {
  JigId: string
  DesiredOwnerEmail: string
  DesiredOwnerTenantID?: number
  action?: string
}

export interface ChangeOwnerTenantRequest {
  JigId: string
  DesiredOwnerTenantID: number
}

export interface ChangeJigOwnerResult {
  message: string
  status: string
}

export interface JigVisibilities {
  jigId: string
  visibility: string
  regenPassword: boolean
  version: number
}

export interface JigShareURLData {
  jigId: string
  requestInFlight: boolean
  shareURL: string
}

export interface SharedJigItem {
  id: string
  name: string
  author: string
  thumbnail: string
  deeplinkUrl: string
  visibility: string
  isOwner: boolean
  teamShared: boolean
  isStaffOwned: boolean
}

export interface LockRequestPayload {
  jigId: string
  tenantId: number
}

export interface BreakLockRequestPayload {
  jigId: string
}

export interface UpdateJigMetadataRequest {
  jigMetadata: JigMetadata
  queryParams?: StandardObject
  defaultErrorHandleRequired?: boolean
}

export interface JigTransferMetadata {
  id: string
  ownerTenantID: number
  uid: number
  projectName: string
}

export interface LoadTenantJigsPayload {
  page: number
  limit: number
  filters: any
  sort: {
    byId: string
    by: string
    value: JigConst.jigsListSortTerm
  }
}

export interface LoadMyJigsPayload {
  requestUserId: number
  loadConfig: LoadTenantJigsPayload
  sharedWithUser: boolean
  mutationName?: string
  actionDetails?: string
  checkOtherUser?: boolean
  listingUrl?: string
}

export interface JigsListSortTerm {
  asc: JigConst.jigsListSortTerm.asc
  desc: JigConst.jigsListSortTerm.desc
}

export interface JigVisibilityOption {
  icon: string
  text: string
}

export interface JigVisibilityListOption {
  icon: string
  text: string
  value: JigConst.JigVisibilities
  disabled: boolean
}

export interface JigVisibilityEventData {
  myTenantIndex?: number
  teamSharePermissions?: JigConst.SharePermission[]
  visibility?: string
  regeneratePassword?: boolean
  callback: Function
}

export interface JigTeamSharePermissionCallbackPayload {
  currentPermission: TenantJigPermissions
  newTeamPermissions: TenantJigPermissions
}
