import { AppConst, UtilsConst } from '@/constants'
import { getInstance } from '@/plugins/auth0'
import { StoreConst } from '@/store/constants'
import { default as appState } from '@/store/modules/app/state'
import { HTTPRequest } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
declare var consoleLog: any
declare var consoleWarn: any

/**
 * Performs an API request using a custom Axios configuration and handles common request requirements such as
 * authentication, headers, and error handling.
 *
 * @param {HTTPRequest} request - The HTTPRequest object extends AxiosRequestConfig type.
 * @param {any} context - The application context, used for state management.
 * @param {string} mutation - The mutation name to commit with the response data. If not provided, request result data is returned instead.
 * @param {string} errorMsg - Custom error message to display in case of request failure.
 * @param {string} [loadingStateMutationName=StoreConst.StoreHelper.loadingMutationName] - The mutation name to handle loading state.
 * @param {any} [customHeaders={}] - Optional custom headers to be added to the request.
 * @param {any} [customErrorHandler=undefined] - Optional custom error handler for managing request errors.
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the request succeeds, otherwise handles errors.
 *
 * **Functionality**:
 * - Configures Axios request options based on the provided `request` object.
 * - Attaches an authorization header with a Bearer token if available from `authService`.
 * - Merges custom headers provided through `customHeaders` into the request config.
 * - Delegates the actual API call to `apiRequestCustomConfig`, which applies the configured request options and manages loading state and errors.
 */

export async function apiRequest(
  request: HTTPRequest,
  context: any,
  mutation: string,
  errorMsg: string,
  loadingStateMutationName: string = StoreConst.StoreHelper.loadingMutationName,
  customHeaders: any = {},
  customErrorHandler: any = undefined
): Promise<boolean> {
  // Make sure we copy over all options that is part of AxiosRequestConfig
  const { action, body, ...axiosRequestConfig } = request
  const config: AxiosRequestConfig = axiosRequestConfig
  config.baseURL = AppConst.apiDomain

  const authService = getInstance()
  const crudMethod = [UtilsConst.HTTPAction.POST, UtilsConst.HTTPAction.PUT, UtilsConst.HTTPAction.DELETE]

  if (request.cancelToken) {
    config.cancelToken = request.cancelToken
  }

  if (request.baseURL) {
    config.baseURL = request.baseURL
  }

  if (config.headers == null) {
    config.headers = {}
  }

  if (authService.accessToken) {
    config.headers.Authorization = `Bearer ${authService.accessToken}`
  }

  // In my actual testing, as long as user has signed in successfully, then the first ApiRequest always have myTenant
  // set successfully. In theory we will always have TenantID set successfully.
  // In case if any edge case happened, we log the warning for debug later.
  if (appState.myTenant.ID !== TenantHelpers.InvalidTenantID) {
    config.headers[UtilsConst.RequestHeaders.CustomHeaderKeyTenantId] = appState.myTenant.ID
  } else {
    consoleWarn(`Request '${request.url}' has no tenantId attached in headers`)
  }

  if (crudMethod.includes(request.action)) {
    config.headers[UtilsConst.RequestHeaders.CustomHeaderKeyAppName] = AppConst.appName
  }

  if (customHeaders != null && config.headers != null) {
    for (const key of Object.keys(customHeaders)) {
      config.headers[key] = customHeaders[key]
    }
  }
  return apiRequestCustomConfig(request, context, mutation, errorMsg, config, loadingStateMutationName, customErrorHandler)
}

/**
 * Executes an API request using a pre-configured Axios request and manages the application state during the request lifecycle.
 *
 * @param {HTTPRequest} request - The HTTPRequest object extends AxiosRequestConfig type.
 * @param {any} context - The application context, used to commit Vuex mutations.
 * @param {string} mutation - The mutation name to commit with the response data.
 * @param {string} errorMsg - Custom error message to display in case of request failure.
 * @param {AxiosRequestConfig} config - Axios configuration for the request.
 * @param {string} [loadingStateMutationName=AppConst.StoreHelper.loadingMutationName] - Mutation name to toggle loading state.
 * @param {any} [customErrorHandler=undefined] - Optional custom error handler function for managing errors.
 *
 * @returns {Promise<boolean>} - Resolves to `true` if the request is successful, or `false` if an error occurs.
 *
 * **Functionality**:
 * - Commits a loading state mutation to indicate that the request is in progress, if `loadingStateMutationName` is provided.
 * - Sends the HTTP request using the Axios, performing the action specified (`GET`, `POST`, `PUT`, `DELETE`).
 * - Extracts and commits the response data to store via the specified mutation.
 * - Handles errors through `customErrorHandler` or default error hander `onApiCatchError`.
 * - Updates the loading state to `false` after the request completes, regardless of success or failure.
 *
 * **Request Actions**:
 * - **GET**: Fetches data from the specified URL.
 * - **POST/PUT**: Sends data in the request body.
 * - **DELETE**: Deletes a resource and extracts the ID from the request URL for the mutation payload.
 *
 * **Error Handling**:
 * - If `customErrorHandler` is provided, it will be called for custom error management.
 * - Handles Axios request cancellations separately.
 * - Default error handling is performed via `onApiCatchError`, logging the error and updating the application state.
 *
 * **Return Value**:
 * - If a mutation is specified, the response data is committed, and the function resolves to `true`.
 * - If no mutation is provided, the raw response data is returned.
 */

export async function apiRequestCustomConfig(
  request: HTTPRequest,
  context: any,
  mutation: string,
  errorMsg: string,
  config: AxiosRequestConfig,
  loadingStateMutationName: string = StoreConst.StoreHelper.loadingMutationName,
  customErrorHandler: any = undefined
): Promise<boolean> {
  if (loadingStateMutationName !== '') {
    context.commit(loadingStateMutationName, true, { root: true })
  }
  let response!: AxiosResponse<any>
  let mutationData: any = {}

  if (!request.url) {
    throw new Error(`Request url is required`)
  }

  try {
    if (request.action === UtilsConst.HTTPAction.GET) {
      response = await Axios.get(request.url, config)
      mutationData = response.data
    } else if (request.action === UtilsConst.HTTPAction.POST) {
      response = await Axios.post(request.url, request.body, config)
      mutationData = response.data
    } else if (request.action === UtilsConst.HTTPAction.PUT) {
      response = await Axios.put(request.url, request.body, config)
      mutationData = response.data
    } else if (request.action === UtilsConst.HTTPAction.DELETE) {
      response = await Axios.delete(request.url, config)
      // The deleted id element is always the last part of the path
      const pathname = config.baseURL ? new URL(`${config.baseURL}${request.url}`).pathname : new URL(request.url).pathname
      mutationData = pathname.split('/').pop()
    } else {
      throw new RangeError(`The request.action ${request.action} is not supported.`)
    }
  } catch (error) {
    if (customErrorHandler) {
      customErrorHandler(context, error, errorMsg, onApiCatchError)
    } else if (Axios.isCancel(error)) {
      consoleLog('Request is cancelled', error)
    } else {
      onApiCatchError(context, error, errorMsg)
    }
    return false
  }

  let returnResult = true

  if (mutation.length > 0) {
    context.commit(mutation, mutationData)
  } else {
    returnResult = mutationData
  }
  if (loadingStateMutationName !== '') {
    context.commit(loadingStateMutationName, false, { root: true })
  }
  context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, '', { root: true })

  return returnResult
}

export function onApiCatchError(context: any, error: any, actionDescription: string): void {
  const responseBody = (error && error.response && error.response.data) || 'No Response Body'
  // tslint:disable-next-line:no-console
  consoleLog(`API Error Response Body: ${JSON.stringify(responseBody)}
    \nFull Error: ${JSON.stringify(error)}`)
  if (error.response) {
    let extraInfo = ''
    if (
      error.response.data !== undefined &&
      error.response.data !== null &&
      error.response.data.message !== undefined &&
      error.response.data.message.length > 0
    ) {
      extraInfo = ' Message: ' + error.response.data.message
    }
    context.commit(
      StoreConst.StoreHelper.SetApiErrorMutationName,
      `Something went wrong ${actionDescription}. The response code: ${error.response.status}.${extraInfo}`,
      { root: true }
    )
  } else if (error.request) {
    context.commit(
      StoreConst.StoreHelper.SetApiErrorMutationName,
      `Something went wrong ${actionDescription}. The request was made but no response was received.`,
      { root: true }
    )
  } else {
    context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong ${actionDescription}.`, { root: true })
  }
  context.commit(StoreConst.StoreHelper.loadingMutationName, false, { root: true })
  context.commit(StoreConst.StoreHelper.loadingModalMutationName, false, { root: true })
}

// A dev util function to skip the real api request and commit fake data to the mutation.
export function fakeApiRequestSuccess(context: any, mutation: string, mutationData: any): Promise<boolean> {
  if (!mutationData) {
    // This is just here to show example usage, EG:
    // const mutationData: any = { propName: 'Fake Prop Value' }
    mutationData = [{ Name: 'Fake Tenant Name' }]
  }
  context.commit(mutation, mutationData)
  context.commit(StoreConst.StoreHelper.loadingMutationName, false, { root: true })
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}
