import { set, toggle } from '@/utils/vuex'

export default {
  setApiError(state: any, apiError: string) {
    state.apiError = apiError
  },
  setColor: set('color'),
  setDrawer: set('drawer'),
  setImage: set('image'),
  toggleDrawer: toggle('drawer'),
  changeInitializingState(state: any, initializing: boolean) {
    state.initializing = initializing
  },
  changeLoadingState(state: any, loading: boolean) {
    state.loading = loading
  },
  changeModalLoadingState(state: any, loading: boolean) {
    state.loadingModal = loading
  },
  updateAllTenantsCurrentPage(state: any, currentPage: number) {
    state.allTenantsCurrentPage = currentPage
  },
  updateAllTenantsSearchCriteria(state: any, searchCriteria: string) {
    state.allTenantsSearchCriteria = searchCriteria
  },
  updateMyJigsItemsPerPage(state: any, myJigsItemsPerPage: number) {
    state.myJigsItemsPerPage = myJigsItemsPerPage
  },
  updateTeamJigsItemsPerPage(state: any, teamJigsItemsPerPage: number) {
    state.teamJigsItemsPerPage = teamJigsItemsPerPage
  },
  updateTenantsItemsPerPage(state: any, tenantsItemsPerPage: number) {
    state.tenantsItemsPerPage = tenantsItemsPerPage
  },
  updateTenantUsersItemsPerPage(state: any, tenantUsersItemsPerPage: number) {
    state.tenantUsersItemsPerPage = tenantUsersItemsPerPage
  },
}
