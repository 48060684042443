import { AppConst } from '@/constants'
import { CustomerResponse, StripeSubscriptionClass } from '@/modules/subscription/types'
import { Permissions } from '@/security/permissions'
import { PermissionActions } from '@/store/constants'
import {
  JigListPaginatedResponse,
  LoadTenantUsersPayload,
  Tenant,
  TenantLogoImageResponse,
  TenantSetupJob,
  TenantsPaginatedResponse,
  TenantToken,
  TenantUser,
  TenantUserCountResponse,
  UserAttribution,
  UserRoleDefinition,
} from '@/store/modules/app/types'
import {
  CreateCheckoutResponse,
  CreateCustomerPortalResponse,
  DynaconfConfig,
  QueuedStatusResult,
  SetupIntentResponse,
} from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'

declare var consoleLog: any

// Check user permission to assign Team Role value, for easier check in front end later.
// This can avoid extra JS calculation whenever we check user admin/manager permissions.
function updateUserExtraPermissions(state: any, tenantUsers: TenantUser[]) {
  // TenantUserRole state should be set on page init load, which will set TenantHelpers.TenantRoleAdmin value.
  // But just in case it didn't happen, check and set its value.
  if (TenantHelpers.TenantRoleAdmin === '') {
    const teamAdminRole = state.tenantUserRoles.find((t: UserRoleDefinition) => t.Name === TenantHelpers.roleTeamAdmin1)
    TenantHelpers.setTenantRoleAdmin(teamAdminRole != null ? teamAdminRole.DisplayName : 'Team Admin')
  }

  for (const tenantUser of tenantUsers) {
    tenantUser.tenantAdminRoles = []
    tenantUser.extraPermissionNames = []

    // If `tenant:billing_contact` exists, then user is Billing Contact
    if (Permissions.TenantUserHasPermissionAction(tenantUser, Permissions.PermTenant, PermissionActions.BillingContact)) {
      tenantUser.extraPermissionNames.push(TenantHelpers.ExtraPermissionBillingContact)
    }
    // If `tenant:manage` exists, user has extra Team Manager permission (either new Team Admin role or legacy Creator + Team Manager)
    if (Permissions.TenantUserHasPermissionAction(tenantUser, Permissions.PermTenant, PermissionActions.Manage)) {
      tenantUser.tenantAdminRoles.push(TenantHelpers.ExtraPermissionManager)
      tenantUser.extraPermissionNames.push(TenantHelpers.ExtraPermissionManager)
    }
    // If `tenant_jig:manage` exists, user is assigned Team Admin role
    if (Permissions.TenantUserHasPermissionAction(tenantUser, Permissions.PermTenantJigs, PermissionActions.Manage)) {
      tenantUser.tenantAdminRoles.push(TenantHelpers.TenantRoleAdmin)
    }
  }
}

export default {
  updateSuperUserFlag(state: any, isSuperUser: boolean) {
    state.isSuperUser = isSuperUser
  },
  updateQueuedStatus(state: any, queuedStatusResult: QueuedStatusResult) {
    state.queuedStatusResult = queuedStatusResult
  },
  updateCreditCardSetupIntent(state: any, payload: SetupIntentResponse) {
    state.creditCardSetupIntentClientSecret = payload.stripeSetupIntentClientSecret
  },
  updateCustomerPaymentMethod(state: any, payload: CustomerResponse) {
    state.customerWithPaymentMethod = payload
  },
  updateSubscription(state: any, payload: StripeSubscriptionClass) {
    state.subscription = payload
  },
  setforceRerenderKey(state: any, payload: any) {
    state.forceRerenderKey += 1
  },
  updateCheckoutUrl(state: any, payload: CreateCheckoutResponse) {
    state.checkoutUrl = payload.CheckoutURL
  },
  updateCustomerPortalUrl(state: any, payload: CreateCustomerPortalResponse) {
    state.customerPortalUrl = payload.PortalURL
  },
  updateDynaconfConfig(state: any, payload: DynaconfConfig) {
    state.dynaconfConfig.pricing = payload.pricing
    // At the moment we only need fileTypes in our code base.
    // Avoid store unnecessary data in state.
    state.dynaconfConfig.fileformats.fileTypes = payload.fileformats.fileTypes
  },
  updateMyTenant(state: any, tenant: Tenant) {
    TenantHelpers.SetTenantDefaultLogoIfNull(tenant)
    const index = ListHelpers.getIndexById(state.myTenants, 'ID', tenant.ID)
    let tenantIndex = state.myTenantIndex
    if (index >= 0) {
      const existingTenant: Tenant = state.myTenants[index]
      // Preserve UserCount if it's not in the response.
      if (tenant.UserCount === undefined) {
        tenant.UserCount = existingTenant.UserCount
      }
      // Preserve LogoImage
      if (tenant.Logourl === existingTenant.Logourl) {
        tenant.LogoImage = existingTenant.LogoImage
      }
      state.myTenants[index] = Object.assign({}, tenant)
      tenantIndex = index
    } else {
      state.myTenants.push(Object.assign({}, tenant))
      tenantIndex = state.myTenants.length - 1
    }
    const isLibraryDataResetRequired = this.setMyTenantIndex(state, tenantIndex)
    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  updateMyTenantLogo(state: any, imageData: string) {
    if (state.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const tenant = TenantHelpers.GetTenantByIndex(state.myTenantIndex, state.myTenants)
      if (tenant !== undefined) {
        tenant.LogoImage = 'data:image/png;base64,'.concat(imageData)
        if (tenant.LogoUpdated) {
          tenant.LogoUpdated = false
        }
      }
    }
  },
  updateMyTenantLogoByID(state: any, payload: TenantLogoImageResponse) {
    const tenant = TenantHelpers.GetTenantByID(payload.ID, state.myTenants)
    if (tenant !== undefined) {
      tenant.LogoImage = 'data:image/png;base64,'.concat(payload.ImageData)
    }
  },
  updateMyTenants(state: any, tenants: Tenant[]) {
    for (const t of tenants) {
      TenantHelpers.SetTenantDefaultLogoIfNull(t)
      if (t.Subscription.SubscriptionFeatures && t.Subscription.SubscriptionFeatures.stats.jigStats.enabled == null) {
        t.Subscription.SubscriptionFeatures.stats.jigStats.enabled = false
      }
    }

    let existingTenantID = TenantHelpers.InvalidTenantID
    if (state.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      existingTenantID = (state.myTenants[state.myTenantIndex] as Tenant).ID
    }
    state.myTenants = tenants
    let tenantIndex = state.myTenantIndex
    if (existingTenantID !== TenantHelpers.InvalidTenantID) {
      tenantIndex = ListHelpers.getIndexById(state.myTenants, 'ID', existingTenantID)
    }

    // If we have a saved tenant index from a previous session, use it here.
    if (
      state.myPreviousTenantIndex !== TenantHelpers.InvalidTenantIndex &&
      tenantIndex === TenantHelpers.InvalidTenantIndex &&
      state.myPreviousTenantIndex < state.myTenants.length
    ) {
      tenantIndex = state.myPreviousTenantIndex
      state.myPreviousTenantIndex = TenantHelpers.InvalidTenantIndex
    }

    // If we haven't set a myTenantIndex, choose the tenant with the lowest
    // Tenant ID. In the future, we will most likely want to save the most recently
    // used dashboard tenant id for the given user and read that on login and use it below.
    if (tenantIndex === TenantHelpers.InvalidTenantIndex) {
      let lowestTenantId = Number.MAX_SAFE_INTEGER
      for (let i = 0; i < state.myTenants.length; i++) {
        const t: Tenant = state.myTenants[i]
        if (t.ID < lowestTenantId && t.Active) {
          lowestTenantId = t.ID
          tenantIndex = i
        }
      }
      if (lowestTenantId === Number.MAX_SAFE_INTEGER) {
        tenantIndex = tenants.length - 1
      }
    }

    const isLibraryDataResetRequired = this.setMyTenantIndex(state, tenantIndex)
    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  updateMyMemberTenants(state: any, tenantsResponse: TenantToken[]) {
    state.myTenantTokens = tenantsResponse

    if (state.myTenant.ID !== TenantHelpers.InvalidTenantID) {
      state.myTenantToken = state.myTenantTokens.find((token: TenantToken) => token.id === state.myTenant.ID)
    }
  },
  updateMyTenantUsers(state: any, tenantUsers: TenantUser[]) {
    if (tenantUsers.length > 0) {
      const uid = this.authService().uid
      updateUserExtraPermissions(state, tenantUsers)
      state.tenantUsers = tenantUsers

      state.currentSignedInUser = state.tenantUsers.find((user: TenantUser) => user.Uid === uid)

      // tenantuserCount here means `team members` which excludes any `super_users` and `guest_users`
      // Temp way to set tenantUserCount till new API endpoint is ready
      // tenant.UserCount returns all users count including all roles, it's different to concept of team members.
      state.tenantUserCount = state.tenantUsers.filter(
        (user: TenantUser) => user.roles && user.roles.find((role: String) => role !== TenantHelpers.roleGuestViewer1)
      ).length
    } else {
      state.tenantUsers = []
      state.tenantUserCount = 0
      state.currentSignedInUser = null
    }

    if (state.myTenant.ID !== TenantHelpers.InvalidTenantID) {
      state.tenantsUsersMap.set(state.myTenant.ID, state.tenantUsers)
    }

    this.updateHelpHeroData(AppConst.HelpHero.helpHeroTenantUsersCountKey, tenantUsers.length)
  },
  updateTenantsUsersMap(state: any, payload: LoadTenantUsersPayload) {
    if (payload.tenantID == null || payload.tenantID === TenantHelpers.InvalidTenantID) {
      consoleLog('No invalid tenant ID provided, tenantsUsersMap is not updated')
      return
    }

    if (payload.tenantUsers.length > 0) {
      updateUserExtraPermissions(state, payload.tenantUsers)
    }

    // When we update the Map, replace it with a new instance to ensure Vue detects the change.
    // This approach ensures reactivity without requiring significant changes to the logic.
    const newTenantUsersMap = new Map(state.tenantsUsersMap)
    newTenantUsersMap.set(payload.tenantID, payload.tenantUsers)

    state.tenantsUsersMap = newTenantUsersMap
  },
  setMyTenantIndexById(state: any, tenantId: number) {
    const tenantIndex = ListHelpers.getIndexById(state.myTenants, 'ID', tenantId)
    if (tenantIndex < state.myTenants.length && tenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const isLibraryDataResetRequired = this.setMyTenantIndex(state, tenantIndex)
      if (isLibraryDataResetRequired) {
        this.resetLibraryData()
      }
    } else {
      consoleLog('failed mutation setMyTenantIndexById')
    }
  },
  setMyTenantIndex(state: any, tenantIndex: number) {
    const isLibraryDataResetRequired = this.setMyTenantIndex(state, tenantIndex)

    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  setMyPreviousTenantIndex(state: any, tenantIndex: number) {
    state.myPreviousTenantIndex = tenantIndex
  },
  updateTenantUserRoles(state: any, roles: UserRoleDefinition[]) {
    state.tenantUserRoles = roles
    const tenantId = TenantHelpers.GetTenantID(state.myTenantIndex, state.myTenants)
    if (tenantId !== TenantHelpers.InvalidTenantID) {
      for (const r of state.tenantUserRoles) {
        if (r.Name === TenantHelpers.roleTeamAdmin1) {
          TenantHelpers.setTenantRoleAdmin(r.DisplayName)
          break
        }
      }
    }
  },
  updateTenantLogo(state: any, payload: TenantLogoImageResponse) {
    const tenant = TenantHelpers.GetTenantByID(payload.ID, state.tenants)
    if (tenant !== undefined) {
      tenant.LogoImage = 'data:image/png;base64,'.concat(payload.ImageData)
    }
  },
  updateTenantSetupJob(state: any, payload: TenantSetupJob) {
    state.tenantSetupJob = payload
  },
  updateTenants(state: any, tenantsResponse: TenantsPaginatedResponse) {
    for (const t of tenantsResponse.rows) {
      TenantHelpers.SetTenantDefaultLogoIfNull(t)
    }

    state.tenants = tenantsResponse.rows
    state.totalTenantsQty = tenantsResponse.total_rows
    state.totalTenantsPages = tenantsResponse.total_pages
  },
  updateTenant(state: any, tenantResponse: Tenant) {
    for (let i = 0; i < state.tenants.length; i++) {
      const t = state.tenants[i] as Tenant
      if (t.ID === tenantResponse.ID) {
        if (tenantResponse.UserCount === undefined) {
          // Preserve UserCount if it's not in the response.
          tenantResponse.UserCount = t.UserCount
        }
        tenantResponse.LogoImage = t.LogoImage
        TenantHelpers.SetTenantDefaultLogoIfNull(tenantResponse)
        state.tenants[i] = Object.assign({}, tenantResponse)
        break
      }
    }
  },
  updateTenantUserCount(state: any, tenantUserCountData: TenantUserCountResponse) {
    state.tenantUserCount = tenantUserCountData.Data.count
  },
  updateUserAttribution(state: any, userAttribution: UserAttribution) {
    state.userAttribution = userAttribution
  },
}
