import { AppConst, UtilsConst } from '@/constants'
import { StoreConst } from '@/store/constants'
import { apiRequest, onApiCatchError } from '@/store/modules/ApiRequestHelper'
import * as pipeline from '@/store/modules/app/pipeline'
import { LibraryCategory, LibraryEntry } from '@/store/modules/teamlibrary/types'
import { QueueIdResult, UploadThumbnailRequest } from '@/store/types'
import Axios, { AxiosRequestConfig } from 'axios'

declare var consoleError: any

export default {
  loadTenantsLibrary(context: any): Promise<boolean> {
    const urlParams = {
      visibility: 'all',
    }
    const tenantId = this.getMyTenantID(context.rootState.app)
    const url = `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}/library/v1`

    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: url,
        params: urlParams,
      },
      context,
      'updateModelsLibrary',
      `getting tenant library`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },
  loadLibraryCategories(context: any): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.libraryCategoriesUrl}`,
      },
      context,
      'updateLibraryCategories',
      `getting library categories`
    )
  },
  loadLibraryCategoryStates(context: any): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.libraryCategoryStatesUrl}`,
      },
      context,
      'updateLibraryCategoryStates',
      `getting library category states`
    )
  },
  loadLibraryEntry(context: any, payload: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    const url = `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}/library/v1/${payload.Id}`

    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: url,
      },
      context,
      'updateLibraryEntry',
      `getting library entry details, Id: ${payload.Id}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },
  updateLibraryEntry(context: any, libraryEntry: LibraryEntry): Promise<boolean> {
    if (!libraryEntry) {
      consoleError('Empty updateLibraryEntry libraryEntry received:', libraryEntry)
      return new Promise(() => false)
    }
    const tenantId = this.getMyTenantID(context.rootState.app)
    if (tenantId !== libraryEntry.TenantId) {
      return Promise.reject('mismatch between library entry tenant id and currently selected Tenant ID')
    }
    const url = `${AppConst.APIEndpoint.tenantsV1Url}/${libraryEntry.TenantId}/library/v1/${libraryEntry.Id}`
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: url,
        body: libraryEntry,
      },
      context,
      '',
      `updating tenant library entry ${libraryEntry.Id}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },
  upsertLibraryCategories(context: any, payload: LibraryCategory[]): Promise<boolean> {
    if (!payload) {
      consoleError('Empty upsertLibraryCategories payload (LibraryCategory[]) received:', payload)
      return new Promise(() => false)
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.libraryCategoriesUrl}`,
          body: payload,
        },
        context,
        'updateLibraryCategories',
        `upserting library categories`
      )
    }
  },
  deleteLibraryEntryById(context: any, id: string): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    const url = `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}/library/v1/${id}`
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.DELETE,
        url: url,
      },
      context,
      'deleteFromModelsLibraryById',
      `deleting from models library, id: ${id}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },
  // uploadLibraryEntryThumbnail can be awaited like any other action, but internally it takes a little longer
  // because our image compression is done through a seperate queue and service. We poll to update the result of
  // the compression after we upload the initial png.
  async uploadLibraryEntryThumbnail(context: any, payload: UploadThumbnailRequest): Promise<boolean> {
    if (payload == null || payload.Id.length === 0 || !payload.TenantId || payload.Thumbnail.size === 0) {
      consoleError(
        `Invalid uploadLibraryEntryThumbnail payload. Required payload:
        UploadThumbnailRequest {
          Id: string
          TenantId: number
          Thumbnail: File
          IsFirstThumbnail: boolean
        }
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const file = new Blob([payload.Thumbnail], {
        type: 'application/octet-stream',
      })
      const formData = new FormData()
      // We change the thumbnail name before we send it as form data
      formData.append('thumbnail', file, `model-${payload.Id}.png`)
      const urlParams = {
        cl: '6x6', // Compression Level Block Size (copied from Unity client)
        mode: 'thorough', // Compression Speed (copied from Unity client)
      }
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
        },
        params: urlParams,
        timeout: 300 * 1000, // 5 minutes
      }
      const errorMsg = `uploading new library entry thumbnail. ID: ${payload.Id}, file: ${payload.Thumbnail.name}. `
      try {
        return await Axios.post<QueueIdResult>(
          `${AppConst.apiDomain}${AppConst.APIEndpoint.publicModelsUrl}/${payload.Id}/thumbnail/offline`,
          formData,
          config
        ).then(async (postImageResult) => {
          if (postImageResult.status === 200 && postImageResult.data.queueId !== '') {
            try {
              const queuedStatusResult = await pipeline.pollPipelineUntilDone(postImageResult.data.queueId, 20)
              context.commit('updateQueuedStatus', queuedStatusResult)
              context.commit(StoreConst.StoreHelper.loadingMutationName, false, { root: true })
              context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, '', { root: true })
              return true
            } catch (error) {
              onApiCatchError(context, Error(), errorMsg + (error as any).message)
              return false
            }
          } else {
            onApiCatchError(context, Error(), errorMsg + `QueueId missing from response.`)
            return false
          }
        })
      } catch (error) {
        onApiCatchError(context, error, errorMsg)
        return false
      }
    }
  },
}
