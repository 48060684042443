import { UtilsConst } from '@/constants'
import { getInstance } from '@/plugins/auth0'
import { default as appState } from '@/store/modules/app/state'
import { QueuedStatusResult } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig } from 'axios'
declare var consoleWarn: any

const apiDomain = (process.env.VUE_APP_API_DOMAIN as string).replace(/\/$/, '') // Remove trailing slashes from the audience
const queuedStatusUrl = `${apiDomain}/pipeline/result`

// pollPipelineUntilDone will take a `queueId` of an item, and get the status of a pipline item
// `retries` times, waiting `interval` between attempts, until the response indicates processing
// is complete through the status === `done`.
// A response with status 'failed' will stop polling and throw an error.
// If `retries` attempts are made and it's not finished, an error is thrown.
export async function pollPipelineUntilDone(
  queueId: string,
  retries: number = Infinity,
  interval: number = 1000
): Promise<QueuedStatusResult> {
  // const pollAttempts = 20
  const authService = getInstance()
  const configAuthOnly: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${authService.accessToken}`,
    },
  }

  if (appState.myTenant.ID !== TenantHelpers.InvalidTenantID && configAuthOnly.headers) {
    configAuthOnly.headers[UtilsConst.RequestHeaders.CustomHeaderKeyTenantId] = appState.myTenant.ID
  } else {
    consoleWarn(`Pipeline request has no tenantId attached in headers`)
  }

  let queuedStatusResult: QueuedStatusResult = {} as QueuedStatusResult
  function validateQueueStatus(response: any) {
    if (response.status !== 200 || !response.data || response.data.status === 'queued' || response.data.status === 'in-process') {
      // This throw is caught in poll, then the next attempt is made
      throw response
    } else {
      if (response.data && response.status === 'failed') {
        if (response.data.errorMsg) {
          throw new Error(`${response.data.errorMsg}`)
        } else {
          throw new Error(`Could not process compressed thumbnail`)
        }
      } else {
        queuedStatusResult = response.data
      }
    }
  }
  await poll(
    () => Axios.get<QueuedStatusResult>(`${queuedStatusUrl}/${queueId}`, configAuthOnly).then(validateQueueStatus),
    retries,
    interval
  )
  return queuedStatusResult
}

// https://stackoverflow.com/a/44054597/1369262
async function poll(fn: any, retries = Infinity, timeoutBetweenAttempts = 1000): Promise<any> {
  const initialRetries = retries
  const delay = (t: any) => new Promise((resolve) => setTimeout(resolve, t))
  return Promise.resolve()
    .then(fn)
    .catch(function retry(err): any {
      retries--
      if (retries > 0) {
        return delay(timeoutBetweenAttempts).then(fn).catch(retry)
      } else {
        throw new Error(`Tried ${initialRetries} times over
${initialRetries * (timeoutBetweenAttempts / 1000)} seconds and never finished`)
      }
    })
}
