import { AppConst, UtilsConst } from '@/constants'
import { StoreConst } from '@/store/constants'
import { apiRequest } from '@/store/modules/ApiRequestHelper'
import { CreateMultipleInvitePayload, CreateSingleInvitePayload } from '@/store/modules/invites/types'
import { TenantHelpers } from '@/utils/tenant-helpers'

const invitesV1Url = `${AppConst.apiDomain}/invites/v1`
const invitesV1BulkV1Url = `${invitesV1Url}/bulk/v1`

export default {
  createSingle(context: any, payload: CreateSingleInvitePayload) {
    if (context.rootState.app.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.rootState.app.myTenants[context.rootState.app.myTenantIndex]
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }

    const requestPayload = {
      Invitation: {
        id: 0,
        inviteeEmail: payload.email,
        inviterUserID: this.authService().uid,
        tenantID: myTenant.ID,
        resources: [
          {
            resourceID: payload.resourceID,
            resourceHashID: payload.resourceHashID,
            type: payload.resourceType,
            permActions: payload.permActions,
          },
        ],
        app: 'jigspace',
      },
    }
    context.commit('setCreateResponse', {})
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: invitesV1Url,
        body: requestPayload,
      },
      context,
      'setCreateResponse',
      `creating invitation`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      }
    )
  },

  createBulk(context: any, payload: CreateMultipleInvitePayload) {
    if (context.rootState.app.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.rootState.app.myTenants[context.rootState.app.myTenantIndex]
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }

    // Loop over all requests and make sure tenant ID set
    for (const req of payload.requests) {
      if (req.invitation.tenantID === TenantHelpers.InvalidTenantID) {
        req.invitation.tenantID = myTenant.ID
      }
    }

    context.commit('setCreateBulkResponse', {})
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: invitesV1BulkV1Url,
        body: payload,
      },
      context,
      'setCreateBulkResponse',
      `creating bulk invitations`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      }
    )
  },
}
