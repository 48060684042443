import { AppConst, JigConst } from '@/constants'
import {
  ChangeJigOwnerResult,
  CopyJigResult,
  JigMetadata,
  JigShareURLData,
  Permission,
  SaveJigResult,
  SaveJigVisibilityResult,
  UserActivity,
} from '@/store/modules/jig/types'
import { EmbedQueryParamConfig, JigSummaryStats, TenantDashboardReport, UploadThumbnailResult } from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import { JigListPaginatedResponse } from '../app/types'

declare var consoleWarn: any

export default {
  changeIsStatisticsLoadingState(state: any, loading: boolean) {
    state.isStatisticsLoading = loading
  },
  updateMostPopularJigGraph(state: any, reportData: JigSummaryStats[]) {
    state.jigDailyActivityData = reportData
  },
  updateJigViewGraph(state: any, reportData: TenantDashboardReport) {
    state.jigDailyViewsData = reportData.Data.JigSummaryStats
  },
  updateMyJigs(state: any, myJigsResponse: JigListPaginatedResponse) {
    state.myJigs = myJigsResponse.rows
    this.updateHelpHeroData(AppConst.HelpHero.helpHeroJigCountKey, state.myJigs.length)
  },
  deleteFromMyJigsById(state: any, id: string) {
    ListHelpers.deleteById(state.myJigs, 'id', id)
  },
  setRequestingJigShareLink(state: any, changeData: JigShareURLData) {
    const myJigExist = state.myJigs.find((jig: JigMetadata) => jig.Id === changeData.jigId)
    const jigMetadataExist = state.jigMetadata.Id === changeData.jigId

    if (!myJigExist && !jigMetadataExist) {
      return
    }

    if (jigMetadataExist) {
      const newJigMetadata = state.jigMetadata

      newJigMetadata.DeeplinkURLRequesting = changeData.requestInFlight
      newJigMetadata.DeeplinkURL = changeData.shareURL

      // Reset root state to trigger store update so component watch will be triggered
      state.jigMetadata = {
        ...newJigMetadata,
      }

      state.lockPayload.jigId = state.jigMetadata.Id

      // It's either JigDetail or MyJig updates so stop processing further if it's JigDetail updates.
      return
    }

    if (myJigExist) {
      const myJigs = state.myJigs.map((jig: JigMetadata) => {
        const newJig = jig

        if (jig.Id === changeData.jigId) {
          newJig.DeeplinkURLRequesting = changeData.requestInFlight
          newJig.DeeplinkURL = changeData.shareURL
        }

        return newJig
      })

      // Reset root state to trigger store update so component watch will be triggered
      state.myJigs = myJigs
    }
  },
  updateTeamJigs(state: any, teamJigsResponse: JigListPaginatedResponse) {
    state.teamJigs = teamJigsResponse.rows
    this.updateHelpHeroData(AppConst.HelpHero.helpHeroJigCountKey, state.teamJigs.length)
  },
  updateJigMetadata(state: any, jigMetadata: any) {
    state.jigMetadata = jigMetadata
  },
  updateJigMetadataUserActivity(state: any, userActivity: UserActivity) {
    state.jigMetadata.UserActivity = userActivity
  },
  updateJigPermissions(state: any, jigPermissions: Permission[]) {
    if (jigPermissions == null) {
      state.jigDetailPermissions = []
      return
    }

    const validJigPermissions: Permission[] = jigPermissions.filter((perm: Permission) => {
      if (perm.AudName === AppConst.sauronAccount) {
        consoleWarn('Sauron account detected in JigPermission response', perm)
      }
      return perm.AudName !== AppConst.sauronAccount
    })
    state.jigDetailPermissions = validJigPermissions
  },
  updateCopyJigResult(state: any, copyJigResult: CopyJigResult) {
    state.copyJigResult = copyJigResult
  },
  updateSaveJigResult(state: any, saveJigResult: SaveJigResult) {
    state.saveJigResult = saveJigResult
  },
  updateSaveJigVisibilityResult(state: any, saveJigVisibilityResult: SaveJigVisibilityResult) {
    state.saveJigVisibilityResult = saveJigVisibilityResult
  },
  updateChangeJigOwnerResult(state: any, changeOwnerResult: ChangeJigOwnerResult) {
    state.changeJigOwnerResult = changeOwnerResult
  },
  uploadThumbnailResult(state: any, uploadThumbnailResult: UploadThumbnailResult) {
    state.uploadThumbnailResult = uploadThumbnailResult
  },
  updateEmbedParamsConfig(state: any, payload: EmbedQueryParamConfig) {
    state.embedParamsConfig = payload
  },
  updateTeamSharePermissions(state: any, payload: JigConst.SharePermission[]) {
    state.teamSharePermissions = payload
  },
  resetTenantJigCountsAndPagination(state: any) {
    state.myTenantTotalJigCount = -1
    state.myJigCount = -1
    state.myTenantPrivateJigCount = -1
    state.myTenantSharedJigCount = -1
    state.jigListTotalPages = 1
    state.myJigsListCurrentPage = 1
    state.teamJigsListCurrentPage = 1
  },
  updateMaxJigCountValue(state: any, jigListCount: JigListPaginatedResponse) {
    state.maxJigCountValue = jigListCount.total_rows
  },
  updateTenantTotalJigCount(state: any, jigListCount: JigListPaginatedResponse) {
    state.myTenantTotalJigCount = jigListCount.total_rows
    state.jigListTotalPages = jigListCount.total_pages || 1
    state.teamJigsListCurrentPage = jigListCount.page
  },
  updateMyJigCount(state: any, jigListCount: JigListPaginatedResponse) {
    state.myJigCount = jigListCount.total_rows
    state.jigListTotalPages = jigListCount.total_pages || 1
    state.myJigsListCurrentPage = jigListCount.page
  },
  updateTenantPrivateJigCount(state: any, jigListCount: JigListPaginatedResponse) {
    state.myTenantPrivateJigCount = jigListCount.total_rows
    state.jigListTotalPages = jigListCount.total_pages || 1
    state.teamJigsListCurrentPage = jigListCount.page
  },
  updateTenantSharedJigCount(state: any, jigListCount: JigListPaginatedResponse) {
    state.myTenantSharedJigCount = jigListCount.total_rows
    state.jigListTotalPages = jigListCount.total_pages || 1
    state.teamJigsListCurrentPage = jigListCount.page
  },
  updateMyJigsListCurrentPage(state: any, currentPage: number) {
    state.myJigsListCurrentPage = currentPage
  },
  updateTeamJigsListCurrentPage(state: any, currentPage: number) {
    state.teamJigsListCurrentPage = currentPage
  },
  updateLockPayloadJigId(state: any, jigId: string) {
    state.lockPayload.jigId = jigId
  },
  updateLockPayloadTenantId(state: any, tenantId: number) {
    state.lockPayload.tenantId = tenantId
  },
}
