import { PermissionActions } from '@/store/constants'
import { TenantToken, TenantUser } from '@/store/modules/app/types'
import { PermAction, PermissionsMap, SecurityPermission } from '@/store/types'

export class Permissions {
  public static readonly PermTenant: SecurityPermission = {
    Name: 'tenant',
    Actions: [PermissionActions.View, PermissionActions.Manage, PermissionActions.BillingContact],
  }
  public static readonly PermTenantJigs: SecurityPermission = {
    Name: 'tenant_jigs',
    Actions: [PermissionActions.View, PermissionActions.Manage],
  }

  public static readonly PermDashboard: SecurityPermission = {
    Name: 'dashboard',
    Actions: [PermissionActions.Login],
  }

  public static readonly PermJigs: SecurityPermission = {
    Name: 'jigs',
    Actions: [
      PermissionActions.Copy,
      PermissionActions.Create,
      PermissionActions.Delete,
      PermissionActions.Edit,
      PermissionActions.View,
      PermissionActions.Offline,
    ],
  }

  public static readonly PermLibrary: SecurityPermission = {
    Name: 'library',
    Actions: [PermissionActions.Create, PermissionActions.Delete, PermissionActions.Edit, PermissionActions.View],
  }

  public static readonly PermInvite: SecurityPermission = {
    Name: 'invite',
    Actions: [PermissionActions.Create, PermissionActions.Delete, PermissionActions.Edit, PermissionActions.View],
  }

  // Check if the given token has a give permission/action combination.
  public static TokenHasPermissionAction(tenantToken: TenantToken, permission: SecurityPermission, action: PermAction): boolean {
    if (tenantToken != null) {
      return this.hasPermissionAction(tenantToken.permissions, permission, action)
    }
    return false
  }

  public static TenantUserHasPermissionAction(tenantUser: TenantUser, permission: SecurityPermission, action: PermAction): boolean {
    if (tenantUser != null) {
      return this.hasPermissionAction(tenantUser.permissions, permission, action)
    }
    return false
  }

  private static hasPermissionAction(map: PermissionsMap, permission: SecurityPermission, action: PermAction): boolean {
    if (map != null && permission.Name in map) {
      const perm = map[permission.Name]
      return action in perm
    }
    return false
  }
}
