import { CreateCheckoutRequest, CreateCustomerPortalRequest } from '@/modules/subscription/types'
import { StoreConst } from '@/store/constants'
import { apiRequest, onApiCatchError } from '@/store/modules/ApiRequestHelper'
import { DynaconfConfig, PricignListItem, PricingTier, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig } from 'axios'

import { AppConst, TenantConst, UtilsConst } from '@/constants'
import { apiRequestCustomConfig } from '@/store/modules/ApiRequestHelper'
import {
  GetTenantsByUserRequest,
  Tenant,
  TenantCreateRequest,
  TenantLogoImageResponse,
  TenantToken,
  TenantUserAddRequest,
  TenantUserRemoveRequest,
  TenantUserUpdateRequest,
  UserUpdateRequest,
} from '@/store/modules/app/types'
import { UploadTenantLogoRequest } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'

declare var consoleLog: any
declare var consoleError: any

export default {
  createCheckout(context: any, payload: CreateCheckoutRequest) {
    const msg = `creating checkout for user ${payload.UrlParams.email} and tenant ${payload.UrlParams.tenantId}`
    if (
      payload.UrlParams.email.length === 0 ||
      payload.UrlParams.tenantId.length === 0 ||
      payload.UrlParams.successUrl.length === 0 ||
      payload.UrlParams.cancelUrl.length === 0
    ) {
      return new Promise((resolve) => {
        onApiCatchError(context, Error(), `${msg}. Error: missing all parameters.`)
        resolve(false)
      })
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.createStripeCheckoutUrl}`,
          params: payload.UrlParams,
        },
        context,
        'updateCheckoutUrl',
        msg,
        StoreConst.StoreHelper.loadingModalMutationName,
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.UrlParams.tenantId,
        }
      )
    }
  },

  createCustomerPortal(context: any, payload: CreateCustomerPortalRequest) {
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    const msg = `creating customer portal for tenant ${myTenant.ID}`
    if (payload.UrlParams.returnUrl.length === 0) {
      return new Promise((resolve) => {
        onApiCatchError(context, Error(), `${msg}. Error: missing all parameters.`)
        resolve(false)
      })
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.createStripeCustomerPortalUrl}`,
          params: payload.UrlParams,
        },
        context,
        'updateCustomerPortalUrl',
        msg,
        StoreConst.StoreHelper.loadingModalMutationName,
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
        }
      )
    }
  },

  setBranchIOError(context: any, error: any) {
    context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Branch IO - something went wrong: ${error}.`, { root: true })
  },

  // unused in favor of updateCustomerPaymentMethod
  async getCreditCardSetupIntent(context: any, payload: any): Promise<boolean> {
    if (payload.length === 0) {
      return Promise.reject('invalid getCreditCardSetupIntent, payload must be the customerEmail')
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/setup-intents`,
          params: { customerEmail: payload },
        },
        context,
        'updateCreditCardSetupIntent',
        `getting setup intent`
      )
    }
  },

  async updateCustomerPaymentMethod(context: any, payload: any): Promise<boolean> {
    if (
      payload.customerEmail === undefined ||
      payload.customerEmail.length === 0 ||
      payload.paymentMethodId === undefined ||
      payload.paymentMethodId.length === 0
    ) {
      return Promise.reject('invalid updateCustomerPaymentMethod, payload must have customerEmail and paymentMethodId')
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/customer-payment-method`,
          params: { customerEmail: payload.customerEmail, paymentMethodId: payload.paymentMethodId },
        },
        context,
        'updateCustomerPaymentMethod',
        `updating customer payment method`,
        '',
        '',
        payload.onApiError
      )
    }
  },

  // getSubscriptionWithInvoice requires the user to have billing_contact permissions for that tenant
  async getSubscriptionWithInvoice(context: any, payload: any): Promise<boolean> {
    if (payload.customerEmail === undefined || payload.customerEmail.length === 0 || payload.tenantId === undefined) {
      return Promise.reject('invalid getSubscription, payload must have customerEmail and tenantId')
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription-with-invoice`,
          params: { customerEmail: payload.customerEmail, tenantId: payload.tenantId },
        },
        context,
        'updateSubscription',
        `updating subscription`,
        '',
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
        }
      )
    }
  },

  async getSubscription(context: any, payload: any): Promise<boolean> {
    if (payload.customerEmail === undefined || payload.customerEmail.length === 0 || payload.tenantId === undefined) {
      return Promise.reject('invalid getSubscription, payload must have customerEmail and tenantId')
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription`,
          params: { customerEmail: payload.customerEmail, tenantId: payload.tenantId },
        },
        context,
        'updateSubscription',
        `updating subscription`
      )
    }
  },

  // setCancelSubscriptionAtEnd requires the user to have billing_contact permissions for that tenant
  async setCancelSubscriptionAtEnd(context: any, payload: any): Promise<boolean> {
    if (
      payload.stripeSubscriptionId === undefined ||
      payload.stripeSubscriptionId.length === 0 ||
      payload.changedBy === undefined ||
      payload.changedBy.length === 0 ||
      payload.isCancelAtPeriodEnd === undefined ||
      payload.tenantId === undefined
    ) {
      return Promise.reject(
        'invalid setCancelSubscriptionAtEnd, payload must have stripeSubscriptionId and changedBy and isCancelAtPeriodEnd and tenantId'
      )
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription-cancel-at-period-end`,
          body: {
            stripeSubscriptionId: payload.stripeSubscriptionId,
            changedBy: payload.changedBy,
            isCancelAtPeriodEnd: payload.isCancelAtPeriodEnd,
          },
        },
        context,
        'updateSubscription',
        `updating subscription`,
        '',
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
        }
      )
    }
  },

  forceRerenderCoreView(context: any, payload: any) {
    context.commit('setforceRerenderKey', payload)
  },

  async getDynaconfConfig(context: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.state)
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.authService().accessToken}`,
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      },
    }

    const response: any = await Axios.get(`${AppConst.apiDomain}${AppConst.APIEndpoint.dynaconfConfig}`, config)

    if (response.data && response.data.pricing) {
      const pricingSets: { [key: string]: PricignListItem } = {}

      response.data.pricing.sets.forEach((set: any) => {
        pricingSets[set.name] = {
          name: set.name,
          tiers: {},
        }

        set.tiers.forEach((tier: PricingTier) => {
          pricingSets[set.name].tiers[tier.name] = tier
        })
      })

      context.commit('updateDynaconfConfig', {
        pricing: {
          sets: pricingSets,
        },
        fileformats: {
          fileTypes: response.data.fileformats.fileTypes,
        },
      } as DynaconfConfig)
      return true
    } else {
      consoleLog(`Dynoconf config retrieve failed, response was: ${JSON.stringify(response)}`)
      context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong getting dynaconf config.`, { root: true })
      return false
    }
  },

  loadMyTenants(context: any, payload: GetTenantsByUserRequest): Promise<boolean> {
    const uid = this.authService().uid
    if (uid === null || uid <= 0) {
      return Promise.reject('invalid this.authService().uid')
    }
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.usersV1Url}/${uid}/tenants/v1`,
        params: { ...payload.UrlParams },
      },
      context,
      'updateMyTenants',
      `loading my organisation`,
      payload.ModalLoad ? StoreConst.StoreHelper.loadingModalMutationName : StoreConst.StoreHelper.loadingMutationName
    )
  },

  // overwrites the myTenants array with the tenants array
  updateMyTenantsWithNewTenant(context: any, tenant: Tenant) {
    const existingTenatnIndex = context.state.myTenants.findIndex((t: Tenant) => t.ID === tenant.ID)

    if (existingTenatnIndex !== -1) {
      // Use JSON.stringify and parse to deep copy value of the tenant object with ability to handle nested object. Object.assign cannot handle nested objects/references.
      context.state.myTenants[existingTenatnIndex] = AppHelper.deepCopyNestedJsonSafeObject(tenant)

      return
    }

    context.state.myTenants.push(AppHelper.deepCopyNestedJsonSafeObject(tenant))
    context.commit('updateMyTenants', context.state.myTenants)
  },

  updateMyMemberTenants(context: any, memberTenants: TenantToken[]) {
    context.commit('updateMyMemberTenants', memberTenants)
  },

  async setMyTenant(context: any, payload: any) {
    context.commit('setMyTenantIndexById', payload.TenantId)
    await context.dispatch('loadTenantUsers')
  },

  setMyTenantIndex(context: any, tenantIndex: number) {
    context.commit('setMyTenantIndex', tenantIndex)
  },

  setMyTenantLogoByID(context: any, payload: TenantLogoImageResponse) {
    if (payload.ID === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.ID <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    context.commit('updateMyTenantLogoByID', payload)
  },

  setMyPreviousTenantIndex(context: any, tenantIndex: number) {
    context.commit('setMyPreviousTenantIndex', tenantIndex)
  },

  loadMyTenant(context: any, payload: any): Promise<boolean> {
    if (payload.TenantId === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.TenantId <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    const params: any = {}
    if (payload.clearCache) {
      params.timestamp = new Date().valueOf()
    }
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/${payload.TenantId}`,
        params,
      },
      context,
      'updateMyTenant',
      'loading my organisation',
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
      }
    )
  },

  loadMyTenantLogo(context: any, payload: Tenant): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.tenantsImagesV1Url}`,
      },
      context,
      'updateMyTenantLogo',
      'loading my organisation image',
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      }
    )
  },

  /**
   * Loads the users for a specific tenant based on the provided payload.
   * This method handles two scenarios:
   * 1. If a `tenantID` is provided in the payload, it uses that ID to fetch users and updates the `tenantsUsersMap` mutation.
   * 2. If no `tenantID` is provided, it defaults to the current tenant's ID and updates the `myTenantUsers` mutation.
   *
   * The method performs validation to ensure the tenant index and ID are valid before proceeding.
   * It makes an API request to fetch the users and commits the appropriate mutation based on the tenant ID used if mutation name is provided.
   * If mutation name is not provided, request result data is returned instead. See `ApiRequestHelper.ts` for details.
   *
   * @param {any} context - The Vuex context, providing access to state, commit, and other Vuex features.
   * @param {StandardObject} payload - An optional payload containing additional parameters, such as `tenantID` and `loadingMutationName`.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the operation is successful, or rejects with an error message if validation fails.
   * @throws {string} Throws an error if the tenant index or ID is invalid.
   */
  async loadTenantUsers(context: any, payload: StandardObject = {}): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if ((myTenant == null || myTenant.ID <= 0) && (payload.tenantID == null || payload.tenantID <= 0)) {
      return Promise.reject('Invalid Tenant ID')
    }
    // if tenantID is provided in payload, use it instead of myTenantID.
    let tenantID!: number
    let mutationName!: string

    if (payload.tenantID != null && payload.tenantID > 0) {
      tenantID = payload.tenantID
      mutationName = 'updateTenantsUsersMap'
    } else {
      tenantID = myTenant.ID
      mutationName = 'updateMyTenantUsers'
    }

    const result = await apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1`,
      },
      context,
      '',
      'loading my organisation users',
      payload.loadingMutationName || StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantID,
      }
    )

    const mutationData = tenantID === myTenant.ID ? result : { tenantID, tenantUsers: result }

    context.commit(mutationName, mutationData)

    return result
  },

  loadSignedInUserProfile(context: any): Promise<boolean> {
    return getUserAttribution(context)
  },

  loadTenantUserProfile(context: any, uid: number = 0): Promise<boolean> {
    if (uid === 0) {
      return Promise.reject('User uid is not provided')
    }

    return getUserAttribution(context, uid)
  },

  loadTenantUserRoles(context: any): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/roles/v1`,
      },
      context,
      'updateTenantUserRoles',
      'loading tenant user roles list',
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      }
    )
  },

  setTenantLogo(context: any, payload: TenantLogoImageResponse) {
    if (payload.ID === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.ID <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    context.commit('updateTenantLogo', payload)
  },

  async searchPaginatedTenants(context: any, payload?: any) {
    // context.commit('updateTenants', context.state.myTenants)
    const urlParams = payload ? { ...payload } : { ...TenantConst.defaultTenantsPagination }

    if (payload && !payload.page) {
      urlParams.page = TenantConst.defaultTenantsPagination.page
    }

    if (payload && !payload.limit) {
      urlParams.limit = TenantConst.defaultTenantsPagination.limit
    }

    if (payload.name) {
      urlParams.name = payload.name
    } else {
      delete urlParams.name
    }

    if (payload.email) {
      urlParams.email = payload.email
    } else {
      delete urlParams.email
    }

    if (payload.sort) {
      urlParams.sort = payload.sort
    } else {
      delete urlParams.sort
    }

    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}`,
        params: urlParams,
      },
      context,
      'updateTenants',
      'loading tenants search results',
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: context.state.myTenant.ID,
      }
    )
  },

  loadTenantsVisibleToSuperUsers(context: any): Promise<boolean> {
    const uid = this.authService().uid
    if (uid === null || uid <= 0) {
      return Promise.reject('invalid this.authService().uid')
    }
    // Note: This request is only successfully callable by staff users and will return 401 if not staff
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.usersV1Url}/${uid}/tenants/v1`,
        params: { all: 'true' },
      },
      context,
      'updateTenants',
      `loading organisation`
    )
  },

  createTenant(context: any, payload: TenantCreateRequest): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: `${AppConst.APIEndpoint.tenantsV1Url}`,
        body: payload,
      },
      context,
      '',
      `creating organisation`,
      StoreConst.StoreHelper.loadingModalMutationName
    )
  },

  updateTenant(context: any, tenant: Tenant): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.tenantsV1Url}`,
        body: tenant,
      },
      context,
      'updateTenant',
      `updating organisation`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenant.ID,
      }
    )
  },

  updateMyTenant(context: any, tenant: Tenant): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.tenantsV1Url}`,
        body: tenant,
      },
      context,
      'updateMyTenant',
      `updating my organisation`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenant.ID,
      }
    )
  },

  addTenantUser(context: any, payload: TenantUserAddRequest): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1`,
        params: payload.UrlParams,
      },
      context,
      '',
      `adding tenant user`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
      }
    )
  },

  async updateTenantUser(context: any, payload: TenantUserUpdateRequest): Promise<boolean> {
    if (!payload.JigRole) {
      return Promise.reject('User role is empty.')
    }
    if (!payload.Uid) {
      return Promise.reject('User UID is empty.')
    }
    if (!payload.TenantId) {
      return Promise.reject('User Tenant ID is empty.')
    }

    const result: any = {}
    const response: any = await apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1`,
        body: payload,
      },
      context,
      '',
      `updating tenant user`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
      },
      (context: any, error: any, errorMsg: string) => {
        result.isSuccessful = false
        result.error = error

        if (error.response) {
          result.status = error.response.status
        }

        consoleLog(`Updating user (UID: ${payload.Uid}) failed, error: ${JSON.stringify(error.response)}`)

        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Error happened when ${errorMsg}. ${error.response.data.message}`, {
          root: true,
        })
        context.commit(StoreConst.StoreHelper.loadingMutationName, false, { root: true })
        context.commit(StoreConst.StoreHelper.loadingModalMutationName, false, { root: true })
      }
    )

    if (response) {
      result.isSuccessful = true
      result.status = 200
      result.data = response
    }

    return result
  },

  updateSignedInUserAttribution(context: any, payload: UserUpdateRequest): Promise<boolean> {
    return updateUserAttribution(context, payload)
  },

  updateTenantUserAttribution(context: any, payload: UserUpdateRequest): Promise<boolean> {
    if (!payload.id) {
      return Promise.reject('User UID is empty.')
    }

    return updateUserAttribution(context, payload)
  },

  async removeTenantUser(context: any, payload: TenantUserRemoveRequest) {
    const result: any = {}
    const response: any = await apiRequest(
      {
        action: UtilsConst.HTTPAction.DELETE,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1/${payload.Uid}`,
      },
      context,
      '',
      `removing user`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
      },
      (context: any, error: any, errorMsg: string) => {
        result.isSuccessful = false
        result.error = error

        if (error.response) {
          result.status = error.response.status
        }

        consoleLog(`Removing user (UID: ${payload.Uid}) failed, error: ${JSON.stringify(error.response)}`)

        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Error happened when ${errorMsg}. ${error.response.data.message}`, {
          root: true,
        })
        context.commit(StoreConst.StoreHelper.loadingMutationName, false, { root: true })
        context.commit(StoreConst.StoreHelper.loadingModalMutationName, false, { root: true })
      }
    )

    if (response) {
      result.isSuccessful = true
      result.status = 200
      result.data = response
    }

    return result
  },

  async getTenantSetupJob(context: any, payload: any): Promise<boolean> {
    if (!payload || !payload.userEmail || payload.userEmail.length === 0) {
      return Promise.reject('invalid getTenantSetupJob, payload must be the userEmail')
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.tenantSetupJobsUrl}`,
          params: { userEmail: payload.userEmail },
        },
        context,
        'updateTenantSetupJob',
        `getting Tenant Setup Job`,
        '',
        '',
        payload.onApiError
      )
    }
  },

  async createTenantSetupJob(context: any, payload: any): Promise<boolean> {
    if (
      !payload.job ||
      payload.job.customerEmail.length === 0 ||
      payload.job.tenantToCreate === undefined ||
      payload.job.tenantToCreate.Name.length === 0
    ) {
      return Promise.reject(
        'invalid TenantSetupJob. Needs a CustomerEmail, Tenant Name. A CustomerPassword is required if making a new user'
      )
    } else {
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.tenantSetupJobsUrl}`,
          body: payload.job,
        },
        context,
        'updateTenantSetupJob',
        `creating Tenant Setup Job`,
        '',
        '',
        payload.onApiError
      )
    }
  },

  async completeTenantSetupJob(context: any, payload: any): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.completedTenantSetupJobsUrl}`,
      },
      context,
      'updateTenantSetupJob',
      `completing Tenant Setup Job`
    )
  },

  uploadTenantLogoImage(context: any, payload: UploadTenantLogoRequest): Promise<boolean> {
    if (payload == null || payload.TenantId === TenantHelpers.InvalidTenantID || payload.Image.size === 0) {
      consoleError(
        `Invalid uploadTenantLogoImage payload. Required payload:
        UploadTenantLogoRequest {
          TenantId: number
          Image: File
        }
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const file = new Blob([payload.Image], {
        type: 'application/octet-stream',
      })
      const formData = new FormData()
      formData.append('image', file)
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
        },
        timeout: 60000, // 1 min as milliseconds
        baseURL: `${AppConst.apiDomain}`,
      }
      const request = {
        action: UtilsConst.HTTPAction.POST,
        url: `${AppConst.tenantsImagesV1Url}`,
        body: formData,
      }
      const requestPromise = apiRequestCustomConfig(
        request,
        context,
        '',
        `uploading new Tenant logo image. Tenant ID: ${payload.TenantId}, file: ${payload.Image.name}`,
        config,
        StoreConst.StoreHelper.loadingModalMutationName
      )
      return requestPromise
    }
  },

  deleteTenantById(context: any, tenantId: number): Promise<boolean> {
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.DELETE,
        url: `${AppConst.APIEndpoint.tenantsV1Url}`,
      },
      context,
      'teamlibrary/deleteFromModelsLibraryById',
      `deleting organisation, id: ${tenantId}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },
}

function getUserAttribution(context: any, uid?: number): Promise<boolean> {
  let url = `${AppConst.APIEndpoint.usersV1Url}${AppConst.APIEndpoint.profileV1Url}`

  if (uid) {
    url = `${url}/${uid}`
  }

  return apiRequest(
    {
      action: UtilsConst.HTTPAction.GET,
      url,
    },
    context,
    'updateUserAttribution',
    'loading tenant user profile attribution'
  )
}

function updateUserAttribution(context: any, payload: UserUpdateRequest): Promise<boolean> {
  let url = `${AppConst.APIEndpoint.usersV1Url}${AppConst.APIEndpoint.profileV1Url}`

  if (payload.uid != null) {
    url = `${url}/${payload.uid}`
  }

  return apiRequest(
    {
      action: UtilsConst.HTTPAction.PUT,
      url,
      body: payload,
    },
    context,
    '',
    `updating tenant user attribution`
  )
}
