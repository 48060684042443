import { AppConst, JigConst, MueConst, ReportConst, UtilsConst } from '@/constants'
import { StoreConst } from '@/store/constants'
import { apiRequest, apiRequestCustomConfig } from '@/store/modules/ApiRequestHelper'
import {
  BreakLockRequestPayload,
  ChangeJigOwnerRequest,
  ChangeJigOwnerResult,
  ChangeOwnerTenantRequest,
  JigMetadata,
  JigPermissionCreateRequest,
  JigPermissionDeleteRequest,
  JigPermissionUpdateRequest,
  JigShareRequest,
  JigShareURLData,
  JigVisibilities,
  LoadMyJigsPayload,
  LoadTenantJigsPayload,
  LockRequestPayload,
  UpdateJigMetadataRequest,
} from '@/store/modules/jig/types'
import { ReportRequest, UploadThumbnailRequest } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { JigListPaginatedResponse, Tenant } from '../app/types'

declare const consoleLog: any
declare const consoleError: any

const CancelToken = Axios.CancelToken
let myJigsListCall: any = null
let teamJigsListCall: any = null

function craftJigListQueryParamsFilters(urlParams: Record<string, any>, loadConfig: LoadTenantJigsPayload, tenantId: number) {
  for (const key in loadConfig) {
    if (key === 'filters') {
      urlParams.filter = []
      for (const filterKey in loadConfig[key]) {
        const item = loadConfig[key][filterKey]
        // `action` value should be `eq` or `not_eq`. If empty skip this filter
        if (item.action == null || item.action === '') {
          continue
        }

        if (filterKey === JigConst.JigListQueryFilters.tenantOwner) {
          item.value = tenantId
        }

        urlParams.filter.push(`${filterKey},${item.action},${item.value}`)
      }
    } else if (key === 'sort') {
      urlParams.sort = `${loadConfig[key].by},${loadConfig[key].value}`
    } else {
      urlParams[key] = loadConfig[key as keyof LoadTenantJigsPayload]
    }
  }
}

export default {
  executeStatisticReport(context: any, reportRequest: ReportRequest): Promise<boolean> {
    let urlParams = {}
    let myMutation = ''
    const tenantId = this.getMyTenantID(context.rootState.app)

    switch (reportRequest.Name) {
      case ReportConst.Report.TenantDashboard:
        myMutation = 'updateJigViewGraph'
        urlParams = {
          name: reportRequest.Name,
          jig_hash_id: reportRequest.JigHashId,
          start_date: reportRequest.StartDate,
          end_date: reportRequest.EndDate,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case ReportConst.Report.JigViewActivity:
        myMutation = 'updateJigViewGraph'
        urlParams = {
          name: reportRequest.Name,
          jig_hash_id: reportRequest.JigHashId,
          start_date: reportRequest.StartDate,
          end_date: reportRequest.EndDate,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case ReportConst.Report.MostPopularJig:
        myMutation = 'updateMostPopularJigGraph'
        urlParams = {
          name: reportRequest.Name,
          top: reportRequest.Limit,
          reportDays: reportRequest.LookBackDays,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case ReportConst.Report.AppActivities:
        myMutation = 'updateAppActivitiesGraph'
        urlParams = {
          name: reportRequest.Name,
          // some other query params...
        }
        break
      default:
        myMutation = 'mutation' + reportRequest.Name
    }

    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.statisticsUrl}`,
        params: urlParams,
      },
      context,
      myMutation,
      `executing report ${reportRequest.Name} using mutation ${myMutation}`,
      StoreConst.StoreHelper.isStatisticsLoadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  async loadUserOwnedJigs(context: any, payload: LoadMyJigsPayload): Promise<boolean> {
    return await context.dispatch('loadJigCount', {
      ...payload,
      mutationName: payload.mutationName || '',
      actionDetails: payload.actionDetails || `load user`,
    })
  },

  async loadMyJigs(context: any, payload: LoadMyJigsPayload): Promise<boolean> {
    if (!payload.requestUserId) {
      return Promise.reject('Need requestUserId to retreive myJigs.')
    }

    myJigsListCall = payload.sharedWithUser ? CancelToken.source() : undefined

    // As My Jigs page and Team Jigs page are using the same view template,
    // if route changes while GET request is still ongoing plus the Jigs list is reasonable long,
    // it will overwrite the result of new GET request call on new route.
    // Or there will be a noticeable time delay before new list appears.
    // So we need to cancel any ongoing call to avoid situation like Team Jigs are showing on My Jigs page
    // or My Jigs showing on Team Jigs page.
    if (teamJigsListCall) {
      teamJigsListCall.cancel('Aborting request to get Team Jigs before get My Jigs request.')
    }

    const tenantId = this.getMyTenantID(context.rootState.app)
    const requestUrl = `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}${payload.listingUrl || AppConst.APIEndpoint.JigsListingUrl}`
    const mutationName = payload.mutationName != null ? payload.mutationName : 'updateMyJigs'
    const actionDetails = payload.actionDetails != null ? payload.actionDetails : 'my jigs'
    const loadConfig: LoadTenantJigsPayload = payload.loadConfig

    if (!loadConfig.filters[JigConst.JigListQueryFilters.ownerUserId].value) {
      loadConfig.filters[JigConst.JigListQueryFilters.ownerUserId].value = payload.requestUserId
    }

    const urlParams: Record<string, any> = {}
    craftJigListQueryParamsFilters(urlParams, payload.loadConfig, tenantId)

    if (payload.checkOtherUser) {
      urlParams.filter.push(`${JigConst.JigListQueryFilters.tenantOwner},eq,${tenantId}`)
    }

    const result = await apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: requestUrl,
        params: urlParams,
        paramsSerializer: {
          indexes: null, // (1) indexes: null (leads to no brackets), (2) (default) indexes: false (leads to empty brackets), (3) indexes: true (leads to brackets with indexes).
        },
        cancelToken: myJigsListCall ? myJigsListCall.token : undefined,
      },
      context,
      '',
      `getting ${actionDetails}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )

    if (result) {
      if (mutationName) {
        context.commit(mutationName, result)
        context.commit('updateMyJigCount', result)
      }
    }

    myJigsListCall = undefined

    return result
  },

  async loadTeamJigs(context: any, payload: LoadTenantJigsPayload): Promise<boolean> {
    teamJigsListCall = CancelToken.source()

    if (myJigsListCall) {
      myJigsListCall.cancel('Aborting request to get My Jigs before get Team Jigs request.')
    }

    const tenantId = this.getMyTenantID(context.rootState.app)
    const requestUrl = `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}${AppConst.APIEndpoint.JigsListingUrl}`
    const urlParams: Record<string, any> = {}

    craftJigListQueryParamsFilters(urlParams, payload, tenantId)

    const result = await apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: requestUrl,
        params: urlParams,
        paramsSerializer: {
          indexes: null, // by default: false
        },
        cancelToken: teamJigsListCall.token,
      },
      context,
      '',
      `getting team jigs`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )

    if (result) {
      context.commit('updateTeamJigs', result)

      if (urlParams.filter.length === 0) {
        // Shared with team Jigs
        context.commit('updateTenantSharedJigCount', result)
      } else if (urlParams.filter.length === 1) {
        // All Jigs in tenant
        context.commit('updateTenantTotalJigCount', result)
      } else {
        // Private Jigs in tenant
        context.commit('updateTenantPrivateJigCount', result)
      }
    }

    teamJigsListCall = null

    return result
  },

  async loadJigCount(context: any, payload: any = {}): Promise<boolean | JigListPaginatedResponse> {
    if (context.rootState.app.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.rootState.app.myTenants[context.rootState.app.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }

    let requestParams: any = {
      op: 'count',
    }

    const targetUid = payload.requestUserId || this.authService().uid

    if (payload.filter == null) {
      requestParams.filter = [`${JigConst.JigListQueryFilters.tenantOwner},eq,${myTenant.ID}`]

      if (payload.checkOtherUser || myTenant.Subscription.MaxJigsCountPer === 'user') {
        requestParams.filter.push(`${JigConst.JigListQueryFilters.ownerUserId},eq,${targetUid}`)
      }

      requestParams.filter.push(`${JigConst.JigListQueryFilters.sharedWithUser},eq,${payload.sharedWithUser || false}`)
    } else {
      requestParams = {
        ...requestParams,
        filter: [...payload.filter],
      }

      if (payload.checkOtherUser || myTenant.Subscription.MaxJigsCountPer === 'user') {
        // If `owner_user_id` filter is not provided, append it
        if (requestParams.filter.find((f: string) => f.indexOf(`${JigConst.JigListQueryFilters.ownerUserId},eq`) >= 0) == null) {
          requestParams.filter.push(`${JigConst.JigListQueryFilters.ownerUserId},eq,${targetUid}`)
        }

        if (!payload.checkOtherUser) {
          const toRemoveIndex = requestParams.filter.findIndex(
            (f: string) => f.indexOf(`${JigConst.JigListQueryFilters.tenantOwner},eq`) >= 0
          )

          if (toRemoveIndex !== UtilsConst.invalidIndex) {
            requestParams.filter.splice(toRemoveIndex, 1)
          }
        }
      } else if (myTenant.Subscription.MaxJigsCountPer !== 'user') {
        const toRemoveIndex = requestParams.filter.findIndex(
          (f: string) => f.indexOf(`${JigConst.JigListQueryFilters.sharedWithUser},eq`) >= 0
        )

        if (toRemoveIndex !== UtilsConst.invalidIndex) {
          requestParams.filter.splice(toRemoveIndex, 1)
        }
      }

      delete requestParams.page
      delete requestParams.limit
      delete requestParams.sort
    }

    const requestUrl: string =
      payload.requestUrl || `${AppConst.APIEndpoint.tenantsV1Url}/${myTenant.ID}${AppConst.APIEndpoint.JigsListingUrl}`
    const mutationName = payload.mutationName != null ? payload.mutationName : 'updateTenantTotalJigCount'
    const actionName = payload.actionName || 'total Jig count'

    const result: boolean | JigListPaginatedResponse = await apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: requestUrl,
        params: requestParams,
        paramsSerializer: {
          indexes: null, // by default: false
        },
      },
      context,
      mutationName,
      `loading tenant ${actionName}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      }
    )

    if (typeof result !== 'boolean') {
      return {
        ...(result as JigListPaginatedResponse),
        uid: targetUid,
      }
    } else {
      return result
    }
  },

  loadJigMetadata(context: any, payload: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.jigsV2Url}/${payload.jigId}/${AppConst.APIEndpoint.jigMetadataV1}`,
      },
      context,
      'updateJigMetadata',
      `getting jig ${payload.jigId}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  deleteJigById(context: any, payload: any): Promise<boolean> {
    if (!payload.jigId || !payload.jigFormatVersion) {
      return Promise.reject('Need a jigId and jigFormatVersion')
    }
    let url = AppConst.APIEndpoint.myJigsUrl.concat('/', payload.jigId)
    if (payload.jigFormatVersion >= 4) {
      // use v2 if the format version is > 4
      url = AppConst.APIEndpoint.myJigsV2Url.concat('/', payload.jigId)
    }
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.DELETE,
        url,
      },
      context,
      'deleteFromMyJigsById',
      `deleting jig id: ${payload.jigId}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  copyJigById(context: any, payload: any): Promise<boolean> {
    const urlParams = {
      action: 'copy',
      name: payload.newName,
    }
    // Response object we get back on a 201 of the copy endpoint
    // MeJigV2CopyResponse is the structure returned when you copy a V2 Jig.
    // type MeJigV2CopyResponse struct {
    //     Status      string `json:"status"`
    //     HashId      string `json:"id"`
    //     Name        string `json:"name"`
    //     DeeplinkURL string `json:"deeplinkURL,omitempty"`
    //     Visibility  string `json:"visibility"`
    // }
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: `${AppConst.APIEndpoint.meJigsV2Url}/${payload.jigId}`,
        params: urlParams,
      },
      context,
      'updateCopyJigResult',
      `copying jig ${payload.jigId} with new name ${payload.newName}`,
      StoreConst.StoreHelper.loadingMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
      }
    )
  },

  async updateJigMetadata(context: any, payload: UpdateJigMetadataRequest): Promise<boolean> {
    const jigMetadataPayload = payload.jigMetadata

    if (!jigMetadataPayload || !jigMetadataPayload.Id || jigMetadataPayload.Version == null) {
      consoleError(
        `Invalid updateJigMetadata payload. Required payload type: JigMetadata
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const tenantId = this.getMyTenantID(context.rootState.app)

      const requestQueryParam = payload.queryParams ? { ...payload.queryParams } : {}
      const results: any = {}
      requestQueryParam.version = jigMetadataPayload.Version

      results.isSuccess = await apiRequest(
        {
          action: UtilsConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.meJigsV2Url}/${jigMetadataPayload.Id}/${AppConst.APIEndpoint.jigMetadataV1}`,
          body: jigMetadataPayload,
          params: requestQueryParam,
        },
        context,
        'updateSaveJigResult',
        `updating metadata of Jig ${jigMetadataPayload.Id}`,
        StoreConst.StoreHelper.loadingMutationName,
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
        },
        (context: any, error: any, errorMsg: string) => {
          if (error.response) {
            results.status = error.response.status
          } else if (
            error.message.includes(`status code ${MueConst.nonSystemErrors.unprocessableEntity.status}`) ||
            error.message.includes('E040019')
          ) {
            results.status = MueConst.nonSystemErrors.unprocessableEntity.status
          }

          if (payload.defaultErrorHandleRequired) {
            consoleLog(`Updating metadata of Jig ${jigMetadataPayload.Id} failed, error: ${JSON.stringify(error.response)}`)

            context.commit(
              StoreConst.StoreHelper.SetApiErrorMutationName,
              `Error happened when ${errorMsg}. ${error.response.data.message}`,
              { root: true }
            )
          }
        }
      )

      return results
    }
  },

  async updateJigVisibility(context: any, payload: JigVisibilities): Promise<boolean> {
    if (!payload || !payload.visibility || !payload.version == null) {
      consoleError(
        `Invalid updateJigVisibility payload. Required payload:
        JigVisibilities {
          jigId: string
          visibility: string
          regenPassword: boolean
          version: number
        }
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const tenantId = this.getMyTenantID(context.rootState.app)
      const results: any = {}

      results.isSuccess = await apiRequest(
        {
          action: UtilsConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.jigId}/visibility/${payload.visibility}`,
          body: payload,
          params: {
            regenPassword: payload.regenPassword,
            version: payload.version,
          },
        },
        context,
        'updateSaveJigVisibilityResult',
        `updating jig ${payload.jigId} visibility`,
        StoreConst.StoreHelper.loadingMutationName,
        {
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
        },
        (context: any, error: any, errorMsg: string) => {
          if (error.response) {
            results.status = error.response.status
          } else if (
            error.message.includes(`status code ${MueConst.nonSystemErrors.unprocessableEntity.status}`) ||
            error.message.includes('E040019')
          ) {
            results.status = MueConst.nonSystemErrors.unprocessableEntity.status
          }
        }
      )

      return results
    }
  },

  loadJigPermissions(context: any, payload: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.jigId}/permission`,
      },
      context,
      'updateJigPermissions',
      `getting jig permissions`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  createJigPermission(context: any, payload: JigPermissionCreateRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.POST,
        url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.JigId}/permission`,
        params: payload.UrlParams,
        // body: payload.Perm,
      },
      context,
      'updateJigPermissions',
      `creating jig permission`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  deleteJigPermission(context: any, payload: JigPermissionDeleteRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.DELETE,
        url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.ResID}/permission/${payload.PermID}`,
      },
      context,
      '',
      `deleting jig permission`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  updateJigPermission(context: any, payload: JigPermissionUpdateRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.meJigsV2Url}/${payload.JigId}/permissions/v1/${payload.Perm.ID}`,
        body: payload.Perm,
      },
      context,
      '', // We don't do anything with the response body as we need to request all permissions again.
      `updating jig permission`,
      StoreConst.StoreHelper.loadingModalMutationName,
      {
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  clearJigShareLink(context: any, payload: JigShareURLData) {
    payload.requestInFlight = false
    payload.shareURL = ''
    context.commit('setRequestingJigShareLink', payload)
  },

  async changeJigOwner(
    context: any,
    payload: ChangeJigOwnerRequest
  ): Promise<{
    isSuccessful: boolean
    terminate: boolean
  }> {
    if (payload.JigId.length === 0 || payload.DesiredOwnerEmail.length === 0) {
      consoleError(
        `Invalid changeJigOwner payload. Required payload:
        ChangeJigOwnerRequest {
          JigId: string
          DesiredOwnerEmail: string
          DesiredOwnerTenantID?: number
        }
      Received payload:`,
        payload
      )
      context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Invalid changeJigOwner payload received.`, {
        root: true,
      })
      return new Promise(() => ({
        isSuccessful: false,
        terminate: false,
      }))
    } else {
      const urlParams: any = {
        email: payload.DesiredOwnerEmail,
      }
      if (payload.action) {
        urlParams.action = payload.action
      }
      if (payload.DesiredOwnerTenantID) {
        urlParams.tenantID = payload.DesiredOwnerTenantID
      }

      const tenantId = this.getMyTenantID(context.rootState.app)
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
        },
        params: urlParams,
      }

      try {
        const response: any = await Axios.put(`${AppConst.apiDomain}${AppConst.APIEndpoint.myJigsV2Url}/${payload.JigId}/owner`, {}, config)

        if (response.data) {
          context.commit('updateChangeJigOwnerResult', response.data as ChangeJigOwnerResult)

          return {
            isSuccessful: response.data.status === 'success',
            terminate: response.data.message !== `Resource not found: ${payload.JigId}`,
          }
        } else {
          consoleLog(`Jig ownership change failed, response was: ${JSON.stringify(response)}`)
          context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when changing Jig ownership`, { root: true })
          return {
            isSuccessful: false,
            terminate: false,
          }
        }
      } catch (error: any) {
        consoleLog(`Jig ownership change failed, error: ${JSON.stringify(error.response)}`)
        const terminateAllRequests = error.response.data.message !== `Resource not found: ${payload.JigId}`

        if (terminateAllRequests) {
          context.commit(
            StoreConst.StoreHelper.SetApiErrorMutationName,
            `Error happened when changing Jig ownership. ${error.response.data.message}`,
            { root: true }
          )
        }

        return {
          isSuccessful: false,
          terminate: terminateAllRequests,
        }
      }
    }
  },

  changeJigOwnerTenant(context: any, payload: ChangeOwnerTenantRequest): Promise<boolean> {
    const msg = `changing jig owner. Jig ID: to ${payload.JigId}, tenant ID: ${payload.DesiredOwnerTenantID}`
    if (payload.JigId.length === 0 || payload.DesiredOwnerTenantID === TenantHelpers.InvalidTenantID) {
      consoleError(
        `Invalid changeJigOwnerTenant payload. Required payload:
        ChangeOwnerTenantRequest {
          JigId: string
          DesiredOwnerTenantID: number
        }
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const urlParams = {
        tenantID: payload.DesiredOwnerTenantID,
      }
      return apiRequest(
        {
          action: UtilsConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.myJigsUrl}/v2/${payload.JigId}/owner`,
          params: urlParams,
        },
        context,
        'updateChangeJigOwnerResult',
        msg
      )
    }
  },

  async requestJigDeeplinkURL(context: any, payload: JigShareRequest): Promise<boolean> {
    if (payload == null) {
      return new Promise((reject) => reject(false))
    }

    return requestBranchJigLinkViaHttpApi(context, payload.jig, payload.branchIOCustomData, payload.targetApp)
  },

  async uploadJigThumbnail(context: any, payload: UploadThumbnailRequest): Promise<boolean> {
    if (payload == null || payload.Id.length === 0 || !payload.TenantId || payload.Thumbnail.size === 0 || payload.version == null) {
      consoleError(
        `Invalid uploadJigThumbnail payload. Required payload:
        UploadThumbnailRequest {
          Id: string
          TenantId: number
          Thumbnail: File
          IsFirstThumbnail: boolean
          version?: number
          DateUpdated?: string
        }
      Received payload:`,
        payload
      )
      return new Promise(() => false)
    } else {
      const file = new Blob([payload.Thumbnail], {
        type: 'application/octet-stream',
      })
      const formData = new FormData()
      // We change the thumbnail name before we send it as form data
      formData.append('image', file, `jig-${payload.Id}-color`)
      const urlParams = {
        cl: '6x6', // Compression Level Block Size (copied from Unity client)
        mode: 'medium', // Compression Speed (copied from Unity client)
        version: payload.version, // Jig's current version value in JigMetadata
      }
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
        },
        params: urlParams,
        timeout: 300 * 1000, // 5 minutes
        baseURL: `${AppConst.apiDomain}`,
      }
      const request = {
        action: payload.IsFirstThumbnail ? UtilsConst.HTTPAction.POST : UtilsConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.Id}/thumbnail`,
        body: formData,
      }
      const requestResults: any = {}
      requestResults.isSuccess = await apiRequestCustomConfig(
        request,
        context,
        'uploadThumbnailResult',
        `uploading new Jig thumbnail. Jig ID: ${payload.Id}, file: ${payload.Thumbnail.name}`,
        config,
        undefined,
        (context: any, error: any, errorMsg: string, onApiCatchError: any) => {
          if (error.response) {
            requestResults.status = error.response.status
            // When SUP error happens, we use a custom error message in UI, all other errors should use default API error catch event.
            if (
              requestResults.status !== MueConst.nonSystemErrors.unprocessableEntity.status &&
              requestResults.status !== MueConst.nonSystemErrors.conflict.status
            ) {
              onApiCatchError(context, error, errorMsg)
            }
          } else if (
            error.message.includes(`status code ${MueConst.nonSystemErrors.unprocessableEntity.status}`) ||
            error.message.includes('E040019')
          ) {
            requestResults.status = MueConst.nonSystemErrors.unprocessableEntity.status
          } else if (error.message.includes(`status code ${MueConst.nonSystemErrors.conflict.status}`)) {
            requestResults.status = MueConst.nonSystemErrors.conflict.status
          }
        }
      )

      return requestResults
    }
  },

  // create lock POST
  async createLock(context: any, payload: LockRequestPayload) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.authService().accessToken}`,
        [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
      },
      params: {
        action: MueConst.LockAction.createLock,
      },
      baseURL: `${AppConst.apiDomain}`,
    }

    try {
      const response: any = await Axios.post(`${AppConst.APIEndpoint.meJigsV2Url}/${payload.jigId}`, {}, config)

      if (response.status === MueConst.LockResponseCode.lockObtained || response.status === MueConst.LockResponseCode.lockUpdated) {
        return {
          isSuccessful: true,
          status: response.status,
          message: 'Lock obtained.',
        }
      } else if (response.status === MueConst.LockResponseCode.lockAlreadyExists) {
        context.commit('updateJigMetadataUserActivity', response.data.UserActivity)
        return {
          isSuccessful: false,
          status: response.status,
          message: 'Lock already exists.',
        }
      } else {
        consoleLog(`Jig lock creation failed, response was: ${JSON.stringify(response)}`)
        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when creating a Jig lock`, {
          root: true,
        })
        return {
          isSuccessful: false,
          status: response.status,
          message: 'Something went wrong when creating a Jig lock',
        }
      }
    } catch (error: any) {
      consoleLog(`Jig lock creation failed, error: ${error.response ? JSON.stringify(error.response) : JSON.stringify(error)}`)
      let errorMessage = 'Something went wrong when creating a Jig lock'

      if (error.response && error.response.status === MueConst.LockResponseCode.lockAlreadyExists) {
        context.commit('updateJigMetadataUserActivity', error.response.data.UserActivity)
        errorMessage = 'Lock already exists.'
      } else if (error.name === 'AxiosError') {
        context.commit(
          StoreConst.StoreHelper.SetApiErrorMutationName,
          `Axios error happened when trying to create a Jig lock. ${error.message}`,
          { root: true }
        )
      } else {
        context.commit(
          StoreConst.StoreHelper.SetApiErrorMutationName,
          `Error happened when trying to create a Jig lock. ${error.response.data.message || ''}`,
          { root: true }
        )
      }

      return {
        isSuccessful: false,
        status: error.response ? error.response.status : error.status,
        message: errorMessage,
      }
    }
  },

  // release lock
  async releaseLock(context: any, payload: LockRequestPayload) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.authService().accessToken}`,
        [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
      },
      params: {
        action: MueConst.LockAction.releaseLock,
      },
      baseURL: `${AppConst.apiDomain}`,
    }

    try {
      const response: any = await Axios.put(`${AppConst.APIEndpoint.meJigsV2Url}/${payload.jigId}`, {}, config)

      if (response.status === MueConst.LockResponseCode.lockRemoved) {
        context.commit('updateJigMetadataUserActivity', response.data.UserActivity)
        return {
          isSuccessful: true,
          status: response.status,
          message: 'Lock removed.',
        }
      } else {
        consoleLog(`Jig lock release failed, response was: ${JSON.stringify(response)}`)
        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when releasing a Jig lock`, {
          root: true,
        })
        return {
          isSuccessful: false,
          status: response.status,
          message: 'Something went wrong when releasing a Jig lock',
        }
      }
    } catch (error: any) {
      consoleLog(`Jig lock release failed, error: ${error.response ? JSON.stringify(error.response) : JSON.stringify(error)}`)
      context.commit(
        StoreConst.StoreHelper.SetApiErrorMutationName,
        `Error happened when trying to release a Jig lock. ${error.response ? error.response.data.message : error.message}`,
        { root: true }
      )
      return {
        isSuccessful: false,
        status: error.response ? error.response.status : error.status,
        message: 'Something went wrong when releasing a Jig lock',
      }
    }
  },

  // notify awaiting lock
  async notifyAwaitingLock(context: any, payload: LockRequestPayload) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.authService().accessToken}`,
        [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
      },
      params: {
        action: MueConst.LockAction.notifyAwaitingLock,
        medium: 'email',
      },
      baseURL: `${AppConst.apiDomain}`,
    }

    try {
      const response: any = await Axios.put(`${AppConst.APIEndpoint.meJigsV2Url}/${payload.jigId}`, {}, config)

      if (response.status === MueConst.LockResponseCode.emailSent) {
        return {
          isSuccessful: true,
          status: response.status,
          message: response.data.message,
          sendStatus: response.data.status,
        }
      } else {
        consoleLog(`Notifying awaiting Jig lock email send failed, response was: ${JSON.stringify(response)}`)
        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when sending awaiting Jig lock email`, {
          root: true,
        })
        return {
          isSuccessful: false,
          status: response.status,
          message: 'Something went wrong when sending awaiting Jig lock email',
          sendStatus: MueConst.MUENotifyEmailStatus.failed,
        }
      }
    } catch (error: any) {
      consoleLog(`Notifying awaiting Jig lock email send failed, error: ${JSON.stringify(error.response)}`)

      if (error.response.status !== MueConst.MUENotifyEmailStatus.invalidJigIDCode) {
        context.commit(
          StoreConst.StoreHelper.SetApiErrorMutationName,
          `Error happened when trying to send awaiting Jig lock email. ${error.response.data.message}`,
          { root: true }
        )
      }

      return {
        isSuccessful: false,
        status: error.response.status,
        message: 'Something went wrong when sending awaiting Jig lock email',
        sendStatus:
          error.response.status === MueConst.MUENotifyEmailStatus.invalidJigIDCode
            ? MueConst.MUENotifyEmailStatus.invalidJigID
            : MueConst.MUENotifyEmailStatus.failed,
      }
    }
  },

  // break an editing lock - staff superuser only
  async breakLock(context: any, payload: BreakLockRequestPayload) {
    const tenantId = this.getMyTenantID(context.rootState.app)
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.authService().accessToken}`,
        [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
        [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      },
      params: {
        action: MueConst.LockAction.breakLock,
      },
      baseURL: `${AppConst.apiDomain}`,
    }

    try {
      const response: any = await Axios.put(`${AppConst.APIEndpoint.jigsV2Url}/${payload.jigId}`, {}, config)

      if (response && response.status === MueConst.LockResponseCode.lockRemoved) {
        return {
          isSuccessful: true,
          status: response.status,
          message: 'Lock removed.',
        }
      } else {
        consoleLog(`Jig lock break failed, response was: ${JSON.stringify(response)}`)
        context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when breaking a Jig lock`, {
          root: true,
        })
        return {
          isSuccessful: false,
          status: response ? response.status : -1,
          message: 'Something went wrong when breaking a Jig lock.',
        }
      }
    } catch (error: any) {
      consoleLog(`Jig lock break failed, error: ${JSON.stringify(error.response)}`)

      context.commit(
        StoreConst.StoreHelper.SetApiErrorMutationName,
        `Error happened when trying to break a Jig lock. ${error.response ? error.response.data.message : ''}`,
        { root: true }
      )

      return {
        isSuccessful: false,
        status: error.response ? error.response.status : -1,
        message: 'Something went wrong when breaking a Jig lock.',
      }
    }
  },
}

async function requestBranchJigLinkViaHttpApi(context: any, jig: JigMetadata, requestData: any, targetApp: string): Promise<boolean> {
  if (context === null) {
    return new Promise((reject) => reject(false))
  }

  const jigDeeplinkURLRequesting = jig.DeeplinkURLRequesting

  // Can't request the same share link at the same time.
  if (jigDeeplinkURLRequesting !== undefined && jigDeeplinkURLRequesting) {
    return new Promise((reject) => reject(false))
  }

  const deeplink = jig.DeeplinkURL

  // We've already got a share url, return success
  if (deeplink !== undefined && deeplink.length > 0) {
    return new Promise((resolve) => resolve(true))
  }

  const jigID = jig.Id
  const tenantId = this.getMyTenantID(context.rootState.app)

  context.commit('setRequestingJigShareLink', {
    jigId: jigID,
    requestInFlight: true,
    shareURL: '',
  } as JigShareURLData)

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${this.authService().accessToken}`,
      [UtilsConst.RequestHeaders.CustomHeaderKeyAppName]: AppConst.appName,
      [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    },
  }

  const reqBody = {
    ...requestData,
  }
  let response!: AxiosResponse<any>

  const url = `${AppConst.apiDomain}${AppConst.APIEndpoint.jigsV1Url}/${jigID}/deeplinks/v1?target_app=${targetApp}`
  response = await Axios.post(url, reqBody, config)
  if (response.data && response.data.url !== undefined && response.data.url.length > 0) {
    consoleLog(`Jig Share URL: ${response.data.url}`)
    context.commit('setRequestingJigShareLink', {
      jigId: jigID,
      requestInFlight: false,
      shareURL: response.data.url,
    } as JigShareURLData)
    return true
  } else {
    consoleLog(`Jig Share URL was not successful, response was: ${JSON.stringify(response)}`)
    context.commit('setRequestingJigShareLink', {
      jigId: jigID,
      requestInFlight: false,
      shareURL: '',
    } as JigShareURLData)
    context.commit(StoreConst.StoreHelper.SetApiErrorMutationName, `Something went wrong getting a link for this Jig.`, {
      root: true,
    })
    return false
  }
}
