import { JigConst } from '@/constants'
import { default as state } from '@/store/modules/jig/state'

export default {
  jigPassword(): string {
    return state.jigMetadata.Password || ''
  },
  jigVisibility(): JigConst.JigVisibilities {
    return state.jigMetadata.ProjectVisibility
  },
}
