import { TenantConst, UtilsConst } from '@/constants'

export default {
  apiError: '',
  drawer: null,
  color: 'warning',
  image: '',
  initializing: false,
  loading: true,
  loadingModal: false,
  // The selected row count to show in data tables
  libraryItemsPerPage: 50,
  myJigsItemsPerPage: UtilsConst.defaultUsersJigsPerPage,
  teamJigsItemsPerPage: UtilsConst.defaultUsersJigsPerPage,
  tenantsItemsPerPage: TenantConst.defaultTenantsPagination.limit,
  tenantUsersItemsPerPage: 50,
  allTenantsCurrentPage: 1,
  allTenantsSearchCriteria: '',
}
