import { PermAction } from '@/store/types'

export namespace StoreConst {
  export enum StoreHelper {
    loadingMutationName = 'utils/changeLoadingState',
    loadingModalMutationName = 'utils/changeModalLoadingState',
    isStatisticsLoadingMutationName = 'jig/changeIsStatisticsLoadingState',
    SetApiErrorMutationName = 'utils/setApiError',
  }
}

export class PermissionActions {
  public static readonly View: PermAction = 'view'
  public static readonly Manage: PermAction = 'manage'
  public static readonly BillingContact: PermAction = 'billing_contact'
  public static readonly Login: PermAction = 'login'
  public static readonly Edit: PermAction = 'edit'
  public static readonly Create: PermAction = 'create'
  public static readonly Delete: PermAction = 'delete'
  public static readonly Copy: PermAction = 'copy'
  public static readonly Offline: PermAction = 'offline'
  public static readonly Small: PermAction = 'small'
  public static readonly Medium: PermAction = 'medium'
  public static readonly Large: PermAction = 'large'
}
